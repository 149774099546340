import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from "swiper";
import { IMAGES } from "../constants/theme";
import 'swiper/swiper-bundle.min.css';

const dataBlog = [
  { img: "https://uploadimage.io/images/2024/08/03/portrait-handsome-tranquil-pensive-male-head-wrap-staring-into-distance.jpg",
    productname:"MyCLNQ Elite",
    username:"Anuj Singh  (Punjab)",
    review:"The fitness watch and subscription plan have transformed my daily routine. Not only do I track my workouts with precision, but the cash coins I've earned have added a fun incentive to stay active. The free consultation was also a game-changer in personalizing my fitness goals. A must-have for anyone serious about their health!"
   },
  { img: "https://uploadimage.io/images/2024/08/03/urban-young-hipster-indian-man-fashionable-yellow-sweatshirt-cool-south-asian-guy-wear-hoodie.jpg",
    productname:"MyCLNQ Connect",
    username:"Amit Kumar (Bangalore) ",
    review:"This subscription is a complete package! The fitness watch is sleek and easy to use, and the cash coins are a great way to stay motivated. What sets this apart is the additional free consultation and medical facilities—it's like having a personal health coach. Highly recommended!",
   },
  { img: "https://uploadimage.io/images/2024/08/03/close-up-portrait-young-man-isolated-black-studio-background-photoshot-real-emotions-male-model-dreaming-smiling-hopeful-happy-facial-expression-human-emotions-concept.jpg",
    productname:"MyCLNQ Elite",
    username:"Vikram Desai (Hyderabad)",
    review:"As someone who struggled to stay consistent with fitness, this subscription was exactly what I needed. The fitness watch is incredibly accurate, and earning cash coins has turned my daily activities into a rewarding experience. The free consultation and medical support made me feel valued as a customer."
   },
   { img: "https://uploadimage.io/images/2024/08/03/young-girl-standing-straight-looking-shoulder-yellow-bomber-jacket-looking-happy.jpg",
    productname:"MyCLNQ Connect",
    username:"Neha Gupta (Kolkata)",
    review:"I was skeptical at first, but this subscription exceeded my expectations. The fitness watch is top-notch, and the rewards system keeps me motivated. The added medical facilities and free consultation were a pleasant surprise. It's more than just a subscription; it's an investment in my health."
   }
];

function ClientSlider() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);
  return (
    <>
       <Swiper
        className="testimonial-swiper"
        slidesPerView={"auto"}
        spaceBetween={0}
        loop={true}
        speed={1500}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: (index, className) => (
            `<span class="${className}"> 0${index + 1}</span>`
          ),
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[Navigation, Pagination]}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.navigation.destroy();
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {dataBlog.map((item, ind) => (
          <SwiperSlide key={ind}>
            <div className="testimonial-1">
              <div className="testimonial-pic">
                <img src={item.img} alt="" />
              </div>
              <ul className="testimonial-rating">
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
              </ul>
              <div className="testimonial-info">
                <p className="testimonial-text">
                  {item.review}
                </p>
                <h4 className="testimonial-name">{item.username}</h4>
                <span className="testimonial-position text-primary">
                  {item.productname}
                </span>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="num-pagination">
          <div
            className="testimonial-button-prev btn-prev"
            ref={navigationPrevRef}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
          <div className="swiper-pagination style-1" ref={paginationRef}></div>
          <div
            className="testimonial-button-next btn-next"
            ref={navigationNextRef}
          >
            <i className="fa-solid fa-arrow-right"></i>
          </div>
        </div>
      </Swiper>
    </>
  );
}
export default ClientSlider;
