import React from 'react'
import { Link } from 'react-router-dom';
import email from '../assets/success/email.png';
import QR from '../assets/success/QR.png';
import GooglePlay from '../assets/success/GooglePlay.jpg';
import image from '../assets/success/image.png';
import check from '../assets/success/check.jpg';
import failed from '../assets/success/failed.png';
import stop1 from '../assets/success/stop1.jpg';
import { useNavigate } from 'react-router-dom';
import './Failure.css'

const Failure = () => {
  const navigate=useNavigate();
  const handleBackBtn=()=>{
    navigate('/')
  }
  return (

   <div className='failure-main'>

<div className='header-failure'>
<div className='payment-failure-text' style={{marginTop:"6rem"}}>
<img src={stop1} />
   <h1>Payment Failed</h1>
   <p className='title-failure' style={{fontSize:"1.2rem"}}>Unfortunately, your payment could not be processed.Please try again or contact support if the issue persists.</p>
   <button className='btn-home-failure' onClick={handleBackBtn}>Back to Home</button>   
   </div>
   
<div className='payment-failure-image'>
  <img src={failed} alt='failed' className='failure-img' style={{ marginRight:"2rem"}}/>
  <p className='title-qr-failure'>Download Our Mobile App to Get Plan Benefits.</p>
   <div className='img-qr-failure'>
   <img className='qr' src={QR} alt='QR'/>    
        <ul style={{  padding: 0, gap: "10px" }}>
                      <li>
                        <a href="https://apps.apple.com/us/app/myclnq/id1436460772" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://uploadimage.io/images/2024/08/03/appstore824ac46ada7a29ee.png"
                            alt="appstore"
                            style={{ border: 0, width: "160px", height:"10rem" }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.ssivixlab.MYCLNQ&hl=en_US"
                          target="_blank"
                          rel="noopener noreferrer">
                          <img
                            src="https://uploadimage.io/images/2024/08/03/playstore5a73419e3782e3f8.png"
                            alt="playstore"
                            className="play-img"
                            style={{border: "0px",width: "190px",objectFit: "cover", height:"3rem"}}
                          />
                        </a>
                      </li>
                    </ul>
    </div>
  </div>
</div>
    </div>
  )
}

export default Failure
