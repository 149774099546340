import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import dataPress from "../api/PressData";
import './press.css'

function PressRelease() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const paginationRef = useRef(null);

  useEffect(() => {
    // This ensures Swiper updates after the DOM elements are rendered
    if (navigationPrevRef.current && navigationNextRef.current) {
      // Swiper needs a slight delay to recognize the DOM elements
      setTimeout(() => {
        if (navigationPrevRef.current && navigationNextRef.current) {
          navigationPrevRef.current.style.display = "block";
          navigationNextRef.current.style.display = "block";
        }
      }, 0);
    }
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-between align-items-center m-b10">
        <div className="col-xl-7">
          <div className="section-head text-center text-md-start">
            <h2 className="title">
              Press <span>Release</span>
            </h2>
          </div>
        </div>
        <div className="col-xl-5 text-md-end d-flex align-items-center justify-content-xl-end justify-content-sm-between justify-content-center m-sm-b30 m-b40">
          <div className="num-pagination">
            <div className="swiper-blog-prev btn-prev" ref={navigationPrevRef}>
              <i className="fa-solid fa-arrow-left"></i>
            </div>
            {/* <div
              className="swiper-blog-pagination1 swiper-pagination style-1"
              ref={paginationRef}
            ></div> */}
            <div className="swiper-blog-next btn-next" ref={navigationNextRef}>
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        className="swiper blog-slider-full blog-slider-wrapper"
        centeredSlides={true}
        slidesPerView={"auto"}
        spaceBetween={30}
        loop={true}
        speed={1500}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          el: paginationRef.current,
          clickable: true,
          renderBullet: (index, className) =>
            `<span class="${className}"> 0${index + 1}</span>`,
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          320: {
            slidesPerView: 1,
            centeredSlides: false,
          },
        }}
      >
        {dataPress.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="dz-card style-1 overlay-shine">
              <div className="dz-media">
                {/* <Link to={`/${item.link}`}>
                  <img src={item.image} alt={item.title} />
                </Link> */}
                <a
                  href={`${item.link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="press-img" src={item.image} alt={item.title} />
                </a>
              </div>
              <div className="dz-info">
                <div className="dz-meta">
                  <ul>
                    <li className="post-author">
                      <span>{item.tag}</span>
                    </li>
                    <li className="post-date">
                      <span>{item.date}</span>
                    </li>
                  </ul>
                </div>
                <h4 className="dz-title">
                  {/* <Link to={`/${item.link}`}>{item.title}</Link> */}
                  {/* <a
                    href={`/${item.link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={item.image} alt={item.title} />
                  </a> */}
                </h4>
                <p>{item.content}</p>
                {/* <Link to={`/${item.link}`} className="btn btn-primary btn-skew">
                  <span>Read More</span>
                </Link> */}
                {/* <a
                  href={`/${item.link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary btn-skew"
                >
                  <span>Read More</span>
                </a> */}
                <button
                  onClick={() =>
                    window.open(item.link, "_blank", "noopener noreferrer")
                  }
                  className="btn btn-primary btn-skew"
                >
                  <span>Read More</span>
                </button>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default PressRelease;
