import React, { useState, useEffect } from "react";
import "./DietForm.css";
import myClnqLogo from "../assets/images/logo.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TiTick } from "react-icons/ti";
import commentDiet from "../assets/images/commentDiet.png";
import config from "../Utils/config";
import axios from "axios";
import ckhk from "../assets/images/logoNew.png";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";

const DietForm = ({ setGenderMain }) => {
  const [stepNumber, setStepNumber] = useState("1");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bmi, setBmi] = useState("");
  const [occupation, setOccupation] = useState("");
  const [selectView, setSelectView] = useState("step1");
  const [medicalCondition, setMedicalCondition] = useState(null);
  const [selectedMedicalConditions, setSelectedMedicalConditions] = useState([]);
  const [cuisine, setCuisine] = useState("");
  const [diet, setDiet] = useState("");
  const [weightError, setWeightError] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedMedicalConditions([]);
  }, [gender]);
  const medicalOptions = [
    { value: "diabetes", label: "Diabetes" },
    { value: "hypertension", label: "Hypertension" },
    { value: "cholesterol", label: "Cholesterol" },
    { value: "thyroid", label: "Thyroid" },
    { value: "fattyLiver", label: "Fatty Liver" },
    ...(gender === "Female" ? [{ value: "pcos", label: "PCOS" }] : []),
  ];

  const handleFirst = () => {
    {
      firstName && lastName && dob && gender && setSelectView("step2");
    }
  };
  const handleSecond = () => {
    if (weight && height && bmi && occupation) {
      setSelectView("step3");
    }
  };
  const handleMedicalConditionChange = (e) => {
    const value = e.target.value;
    setSelectedMedicalConditions((prev) => (prev.includes(value) ? prev.filter((condition) => condition !== value) : [...prev, value]));
  };

  useEffect(() => {
    if (height && weight && height > 0 && weight > 0) {
      const heightInMeters = height / 100;
      const calculatedBmi = (weight / (heightInMeters * heightInMeters)).toFixed(2);
      setBmi(calculatedBmi);
    } else {
      setBmi("");
    }
  }, [height, weight]);
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  function cmToFeetAndInches(cm) {
    let totalInches = cm / 2.54;
    let feet = Math.floor(totalInches / 12);
    let inches = totalInches % 12;
    inches = (inches / 12).toFixed(2).slice(2);
    return `${feet}.${inches}`;
  }

  const handleStep1 = () => {
    setSelectView("step1");
  };
  const handleStep2 = () => {
    setSelectView("step2");
  };
  const handleDobChange = (e) => {
    const inputDate = new Date(e.target.value);
    const today = new Date();
    if (inputDate > today) {
      alert("Date of Birth cannot be in the future");
      setDob("");
    } else {
      setDob(e.target.value);
    }
  };
  console.log(id);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.baseURL}api/v1/dietChart/getOne/${id}`);
        const result = await response.json();
        console.log(result);
        console.log(response.status);
      } catch (error) {
        console.log("catch", error);
        console.log("error", error);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
        navigate("/");
      }
    };
    fetchData();
  }, [id, navigate]);

  const handleLast = () => {
    if(diet && medicalCondition ){
      if(medicalCondition === "No"){
        fetchDietChart();
      }else if (medicalCondition === "Yes" && selectedMedicalConditions.length > 0) {
        fetchDietChart(); 
      } else {
        alert("Please select your medical conditions."); 
      }
    } else {
      alert("Please fill all the required fields.");
    }
   
    }
  

  const fetchDietChart = async () => {
    if (loading) return; 

    setLoading(true); // Set loading to true when API call starts
    setButtonDisabled(true); // Disable button
    let formattedDob = dob.replace(/-/g, "/");
    let heightInFeetAndInches = cmToFeetAndInches(height);
    const medicalConditions = selectedMedicalConditions.map((e) => e.value);
    const dietData = {
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: formattedDob,
      gender: gender,
      height: height.toString(),
      weight: weight.toString(),
      bmi: bmi,
      occupation: occupation,
      cuisinePreference: cuisine,
      dietPreference: diet,
      medicalCondition: medicalConditions,
      isAnyMedicalCondition: medicalCondition,
    };
    try {
      console.log("heelo1");
      const response = await axios.post(`${config.baseURL}api/v1/dietChart/${id}`, { ...dietData });
      const message = await response.data.message;
      swal("Success", message).then(() => {
        navigate("/"); // Redirect to home page after SweetAlert is closed
      });
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.data && error.response.data.message) {
        swal("Error", error.response.data.message).then(() => {
          navigate("/"); 
        });
        console.log(error.response.data.message);
      } else {
        swal("Error", "An unexpected error occurred").then(() => {
          navigate("/"); 
        });
      }
    }finally {
      setLoading(false); // Reset loading state
      setButtonDisabled(false); // Re-enable button
    }
  };
  const handleSelectChange = (e) => {
    setSelectedMedicalConditions(e);
  };
  const customStyles = {
    container: (provided) => ({
      ...provided,
      border: "none", // Remove the default border
    }),
    control: (provided) => ({
      ...provided,
      border: "none", // Remove the default border
      borderBottom: "1px solid grey", // Add black bottom border
      boxShadow: "none", // Remove box shadow
      borderRadius: "0.3rem", // Remove border radius if needed
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#FF4848" : "white", // Hover color
      color: state.isFocused ? "white" : "black", // Text color when hovered
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: "180px", // Decrease the max height of the dropdown
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "180px", // Decrease the max height of the list inside the dropdown
    }),
  };

  return (
    <div className="diet-form-container">
      <div className="section1">
        <img src={myClnqLogo} className="logo" />
        <img src={commentDiet} className="comment-img" />
      </div>
      <div className="form-container">
        <div className="img-ckhk">
          <img src={ckhk} className="ckhk-image" />
        </div>
        <div className="steps-main">
          {selectView === "step1" ? (
            <button style={{ backgroundColor: " #ff4848", color: "white", border: "none" }}>1</button>
          ) : (
            <button style={{ backgroundColor: " #ff4848", color: "white", border: "none" }} onClick={handleStep1}>
              <TiTick color="white" size={20} />
            </button>
          )}
          {selectView === "step3" ? null : (
            <button style={selectView === "step2" ? { backgroundColor: " #ff4848", color: "white", border: "none" } : null}>2</button>
          )}
          {selectView === "step3" && (
            <button style={{ backgroundColor: " #ff4848", color: "white", border: "none" }} onClick={handleStep2}>
              <TiTick color="white" size={20} />
            </button>
          )}
          <button style={selectView === "step3" ? { backgroundColor: " #ff4848", color: "white", border: "none" } : null}>3</button>
        </div>
        <hr className="hr-line" />
        {/* <p>step {selectView === 'step1' ? '1': selectView === 'step2' ? "2" : selectView === 'step3' ? "3": selectView}/3</p> */}
        <br />
        <br />

        <form onSubmit={handleSubmit}>
          <div className="grid-display">
            {selectView === "step1" && (
              <>
                <div className="form-group">
                  <label htmlFor="firstName">First Name:</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="form-control"
                    style={{ borderTop: "none", borderRight: "none", borderLeft: "none" }}
                    value={firstName}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^a-zA-Z\s]/g, ''); 
                      setFirstName(value.trimStart());
                    }}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="lastName">Last Name:</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="form-control"
                    style={{ borderTop: "none", borderRight: "none", borderLeft: "none" }}
                    value={lastName}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                      setLastName(value.trimStart());
                    }}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="dob">Date of Birth:</label>
                  <input
                    type="date"
                    id="dob"
                    name="dob"
                    style={{ borderTop: "none", borderRight: "none", borderLeft: "none" }}
                    className="form-control"
                    value={dob}
                    onChange={handleDobChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="gender">Gender:</label>
                  <select
                    id="gender"
                    name="gender"
                    style={{
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      appearance: "none", // Removes the default dropdown arrow
                      background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140"><polyline points="10,50 70,110 130,50" fill="none" stroke="%23000" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat right center`,
                      backgroundColor: "white",
                      backgroundSize: "12px", // Size of the arrow
                      backgroundPosition: "right 12px center",
                    }}
                    className="form-control"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    required>
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </>
            )}

            {selectView === "step2" && (
              <>
                {" "}
                <div className="form-group">
                  <label htmlFor="height">Height (cm):</label>
                  <input
                    type="number"
                    id="height"
                    name="height"
                    className="form-control"
                    style={{ borderTop: "none", borderRight: "none", borderLeft: "none" }}
                    value={height}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.trim() === '') {
                        setHeight('');
                      } else {
                        setHeight(Math.max(0, Number(value)));
                      }
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="weight">Weight (kg):</label>
                  <input
                    type="number"
                    id="weight"
                    name="weight"
                    className="form-control"
                    style={{ borderTop: "none", borderRight: "none", borderLeft: "none" }}
                    value={weight}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '') {
                        setWeight('');
                      } else {
                        setWeight(Math.max(0, Number(value)));
                      }
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="bmi">BMI:</label>
                  <input
                    type="text"
                    id="bmi"
                    style={{ borderTop: "none", borderRight: "none", borderLeft: "none" }}
                    name="bmi"
                    className="form-control"
                    value={bmi}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="occupation">Occupation:</label>
                  <input
                    type="text"
                    id="occupation"
                    name="occupation"
                    className="form-control"
                    style={{ borderTop: "none", borderRight: "none", borderLeft: "none" }}
                    value={occupation}
                    onChange={(e) => {
                      const value = e.target.value;
                      const trimmedValue = value.trimStart();
                      if (/^[a-zA-Z\s]*$/.test(trimmedValue) && trimmedValue.length <= 50) {
                        setOccupation(trimmedValue);
                      }
                    }}
                    required
                  />
                </div>
              </>
            )}

            {selectView === "step3" && (
              <>
                <div className="form-group">
                  <label htmlFor="cuisine">Cuisine Preference:</label>
                  <select
                    id="cuisine"
                    style={{
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140"><polyline points="10,50 70,110 130,50" fill="none" stroke="%23000" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat right center`,
                      backgroundColor: "white",
                      backgroundSize: "12px", // Size of the arrow
                      backgroundPosition: "right 12px center",
                    }}
                    name="cuisine"
                    className="form-control"
                    value={cuisine}
                    onChange={(e) => setCuisine(e.target.value)}
                    required>
                    <option value="">Select Cuisine</option>
                    <option value="North Indian">North Indian</option>
                    <option value="South Indian">South Indian</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="diet">Diet Preference:</label>
                  <select
                    id="diet"
                    name="diet"
                    style={{
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140"><polyline points="10,50 70,110 130,50" fill="none" stroke="%23000" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat right center`,
                      backgroundColor: "white",
                      backgroundSize: "12px", // Size of the arrow
                      backgroundPosition: "right 12px center",
                    }}
                    className="form-control"
                    value={diet}
                    onChange={(e) => setDiet(e.target.value)}
                    required>
                    <option value="">Select Diet</option>
                    <option value="Vegetarian">Vegetarian</option>
                    <option value="Non Vegetarian">Non-Vegetarian</option>
                    <option value="Eggetarian">Eggetarian</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="medicalCondition">Medical Condition:</label>
                  <select
                    id="medicalCondition"
                    name="medicalCondition"
                    className="form-control"
                    style={{
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140"><polyline points="10,50 70,110 130,50" fill="none" stroke="%23000" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat right center`,
                      backgroundColor: "white",
                      backgroundSize: "12px", // Size of the arrow
                      backgroundPosition: "right 12px center",
                    }}
                    value={medicalCondition || ""}
                    onChange={(e) => setMedicalCondition(e.target.value)}
                    required>
                    <option value="" disabled>
                      Select Medical Conditions
                    </option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>
                {medicalCondition === "Yes" && (
                  <div className="form-group">
                    <label htmlFor="medicalCondition" style={{ overflow: "visible", textWrap: "nowrap", width: "19rem" }}>
                      Do you have any known medical condition(s)?
                    </label>
                    <Select
                      options={medicalOptions}
                      isMulti
                      value={selectedMedicalConditions}
                      onChange={handleSelectChange}
                      placeholder="Select medical conditions..."
                      className="heelo"
                      styles={customStyles}
                    />
                  </div>
                )}
              </>
            )}
          </div>

          {selectView === "step3" && (
            <button type="submit" className={medicalCondition === "yes" ? "btn-diet-plan-submit" : "btn-diet-plan"} onClick={handleLast} disabled={buttonDisabled}>
               {/* {loading ? 'Processing...' : 'Submit'} */}
               Submit
            </button>
          )}
          {selectView === "step2" && (
            <button type="submit" className="btn-diet-plan" onClick={handleSecond}>
              Next
            </button>
          )}
          {selectView === "step1" && (
            <button type="submit" className="btn-diet-plan" onClick={handleFirst}>
              Next
            </button>
          )}
          {/* {selectView === 'step2' &&
            <button type="submit" className="btn-diet-plan" onClick={handleSecond}>
            Next
            </button>  
          } */}
        </form>
      </div>
    </div>
  );
};

export default DietForm;
