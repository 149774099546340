import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IMAGES, SVGICON } from "../constants/theme";
import appstore from "../assets/images/footer/app_store.png";
import gpay from "../assets/images/footer/ongpay.png";
import config from "../Utils/config";

const MenuListArray2 = [
  // { title: "HOME", to: "#home" },
  { title: "Membership", to: "/subscriptions" },
  { title: "Cash Coins", to: "#clients" },
  { title: "Wellness Marketplace", to: "#counter-blog" },
  { title: "Testimonials", to: "#fitness-goal" },
  { title: "News and Articles", to: "#fitness-ideas" },
  // { title: "CONTACT", to: "#workout-slider" },
];
const needHelpMenuList = [
  
  { title: "Terms & Conditions", to: "https://myclnq.co/termsandcondition.html" },
  // {title:'Get App', to:"/get-app"},
  { title: "Privacy Policy", to: "https://myclnq.co/privacypolicy.htm" },
  { title: "Contact Us", to: "#workout-slider" },
  { title: "FAQs", to: "#FAQ" },
  { title: "Get App", to: "/get-app" },
];
const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [counter, setCounter] = useState(0);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (!hasFetched) {
      const fetchCounter = async () => {
        try {
          const response = await axios.get(`${config.baseURL}api/v1/ckhk/counter`);
          if (response.data && response.data.status === "success") {
            let newCount = response.data.data.counter;
            setCounter(12103 + newCount);
            setHasFetched(true);
          }
        } catch (error) {
          console.error("Error fetching counter:", error);
        }
      };

      fetchCounter();
    }
  }, [hasFetched]);

  const scrollToSection = (id) => {
    const element = document.querySelector(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavigation = (path) => {
    if (path.startsWith("http")) {
      window.open(path, "_blank");
      return;
    }
    if (path.startsWith("/")) {
      navigate(path)
      return;
    }

    const [pathname, hash] = path.split("#");

    if (location.pathname === pathname) {
      if (hash) {
        scrollToSection(`#${hash}`);
      }
    } else {
      navigate(pathname);
      setTimeout(() => {
        if (hash) {
          scrollToSection(`#${hash}`);
        }
      }, 100);
    }
  };

  return (
    <>
      <footer className="site-footer style-1 bg-img-fix footer-action" style={{ backgroundImage: "url(" + IMAGES.footerbg + ")" }} id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-md-12">
                <div className="widget widget_about">
                  <div className="footer-logo logo-dark">
                    <Link to={"/"}>
                      <img className="select_logo_dark" src={IMAGES.logo} alt="" />
                    </Link>
                  </div>
                  <p>Your trusted source for health and fitness information.</p>
                  <ul>
                    {/* <li><i className="fas fa-map-marker-alt" /> <span>Address : </span>PO Box W75 Street
                          lan West new queens</li> */}
                    <li>
                      <i className="fas fa-envelope-open" />
                      <span> Email :</span> <a href="mailto:support@myclnq.co.in">support@myclnq.co.in</a>
                    </li>
                    {/* <li>
                      <i className="fas fa-phone"/>
                      <span> Contact :</span> <a href="tel:+91-7676 045 403">+91 -7676 045 403</a>
                      </li> */}
                  </ul>
                  {/* <h6 className="m-t20 m-b15">Our Socials</h6>
                                    <div className="dz-social-icon style-1">
                                        <ul>									
                                            <li>
                                                <Link target="_blank" to="https://www.facebook.com/" rel="noreferrer">
                                                    <i className="fab fa-facebook-f"></i>
                                                </Link>
                                            </li>{" "}
                                            <li>
                                                <Link target="_blank" to="https://twitter.com/?lang=en" rel="noreferrer">
                                                    <i className="fab fa-twitter"></i>
                                                </Link>
                                            </li>{" "}
                                            <li>
                                                <Link target="_blank" to="https://www.instagram.com/myclnqindia/" rel="noreferrer">
                                                    <i className="fab fa-instagram"></i>
                                                </Link>
                                            </li>{" "}
                                            <li>
                                                <Link target="_blank" to="https://www.whatsapp.com/" rel="noreferrer">
                                                    <i className="fa-brands fa-whatsapp"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div> */}
                </div>
              </div>
              <div className="col-xl-3 col-md-4 wow fadeInUp" data-wow-delay="0.4s">
                <div className="widget recent-posts-entry">
                  <h4 className="footer-title">Quick Links</h4>
                  <div className="fw-link">
                    <ul>
                      {MenuListArray2.map((item, index) => (
                        <li key={index} className="menu-item">
                          <a
                            href={item.to}
                            onClick={(e) => {
                              e.preventDefault();
                              handleNavigation(item.to);
                            }}>
                            {item.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-4 wow fadeInUp" data-wow-delay="0.6s">
                <div className="widget widget_locations">
                  <h4 className="footer-title">Need Help</h4>
                  <div className="clearfix">
                    <div className="fw-link">
                      <ul>
                        {needHelpMenuList.map((item, index) => (
                          <li key={index} className="menu-item">
                            <a
                              href={item.to}
                              onClick={(e) => {
                                e.preventDefault();
                                handleNavigation(item.to);
                              }}
                              target={item.to.startsWith("http") ? "_blank" : "_self"}>
                              {item.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-4 wow fadeInUp" data-wow-delay="0.8s">
                <div className="widget widget_working">
                  <h4 className="footer-title">Follow Us on</h4>
                  <div className="dz-social-icon style-1">
                    <ul>
                      <li>
                        <Link target="_blank" to="https://www.facebook.com/profile.php?id=61563160937516" rel="noreferrer">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>{" "}
                      <li>
                        <Link target="_blank" to="https://x.com/myclnqindia" rel="noreferrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            id="Layer_1"
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            style={{ enableBackground: "new 0 0 24 24" }}
                            xmlSpace="preserve">
                            <path
                              d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717 l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339 l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z"
                              fill="white"
                            />
                          </svg>
                        </Link>
                      </li>{" "}
                      <li>
                        <Link target="_blank" to="https://www.instagram.com/myclnqindia/" rel="noreferrer">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>{" "}
                      <li>
                        <a style={{ color: "#f5f5f5" }} target="_blank" href="https://api.whatsapp.com/send?phone=917676045403" rel="noreferrer">
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>
                      <li>
                        <Link target="_blank" to="https://www.linkedin.com/company/myclnq-health-india/" rel="noreferrer">
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div style={{ marginTop: "-15px" }}>
                    <ul style={{ listStyleType: "none", padding: 0, display: "flex", gap: "10px" }}>
                      <li>
                        <a href="https://apps.apple.com/us/app/myclnq/id1436460772" target="_blank" rel="noopener noreferrer">
                          <img src={appstore} alt="appstore" style={{ border: 0, width: "180px", marginTop: "50px" }} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.ssivixlab.MYCLNQ&hl=en_US"
                          target="_blank"
                          rel="noopener noreferrer">
                          <img src={gpay} alt="playstore" className="play-img" style={{ border: "0px", width: "180px", marginTop: "50px" }} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Footer Bottom Part --> */}
        <div className="container">
          <div style={{display:'flex', justifyContent:'center'}}>Visitor Count: {counter}</div>
          <div className="footer-bottom">
            <div className="text-center">
              <span className="copyright-text">
                Copyright © 2024{" "}
                <Link to="https://myclnq.co" rel="noreferrer" target="_blank">
                  MyCLNQ Health
                </Link>
                . All rights reserved.
              </span>
            </div>
          </div>
        </div>
        {/* <img className="girl-img" src={IMAGES.footergril1} alt="" /> */}
        <img className="svg-shape-1 rotate-360" src={IMAGES.footercircle} alt="" />
        <img className="svg-shape-2 rotate-360" src={IMAGES.footercircle} alt="" />
      </footer>
    </>
  );
};

export default Footer;
