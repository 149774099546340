import React, { useState } from "react";
import "./FAQ.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
      question:
        "What is Healthy Bharat Mission?",
      answer:
        "Healthy Bharat Mission is a movement to promote preventive wellness to create a healthier India. With this mission, MyCLNQ aims to play pivotal role in building nation’s digital healthcare ecosystem which will enable all to have access to quality healthcare services thereby creating a lasting impact on nation’s health. The multi-faceted approach of combining financial incentives with healthy habits with the support of smart wearable technology will create a healthier, more prosperous Bharat.",
    },
    {
      question:
        "What is Cash kar har kadam?",
      answer:
        "Cash kar har kadam is a call to action to motivate everyone to adopt a healthier lifestyle and monetize the efforts that will be taken to achieve holistic wellness. Acheving the daily goals will entitle the subscriber to win cash coins which can be redemmed for purchases made on the app.",
    },
    {
      question: "What are the benefits of Healthy Bharat Mission?",
      answer: (
        <ul>
          <li>1. Overall healthy body and mind: Achieving activity goals and monitoring other lifestyle parameters.</li>
          <li>2. Opportunity to monetize efforts: Cash coins on achievement of pre-set wellness and fitness goals.</li>
          <li>3. One-stop solution for all healthcare needs: Doctor consultation, Dietician consultation, Diagnostic services, Health and wellness products available on the app.</li>
          <li>4. Adopting a healthier lifestyle for building a sustainable healthy habit.</li>
        </ul>
      ),
    },
    {
      question:
        "How can I activate the subscription plan after purchase?",
      answer:
        "After purchase of subscription plan the plan is automatically activated. However the pedometer needs syncing with the app to measure steps and other health parameters ",
    },
    
    {
      question:
        "What to do when the smartwatch received is not working?",
      answer:
        (
          <ul>
            <li>After receipt of the smartwatch the customer needs to make a unedited and uninterrupted video of the opening of the box and starting up the watch. If the watch does not start up or does not get charged then the customer can reach out at support@myclnq.co.in and register a complaint. Thereafter the watch needs to be packed in the original packaging alongwith the charger and manual received and handed over to the courier pick up person along with the original invoice</li>
            <li>A replacement will be isssued only after due diligence is done to establish the cause of non functioning of the watch. 
            Any complaint about the watch has to be registered with customer service within 72hrs of receipt of the watch</li>
          </ul>
        )
    },
    {
      question:
        "How can I get in touch with MyCLNQ customer service?",
      answer:
        "Pls e-mail us at support@myclnq.co.in or Whatsapp us at +91-7676045403 ",
    },
    
    {
      question:
        "How can I schedule a doctor consultation?",
      answer:
        "Through MyCLNQ app through video consulatation tab or you can contact us on support@myclnq.co.in or Whatsapp us at +91-7676045403",
    },
    
    {
      question:
        "When can I avail the diet consultation? ",
      answer:
        "1st diet consultation can be availed within 20 days of plan purchase and the 2nd diet plan (applicable to yearly packages only) can be availed in the 6th month from the date of plan purchase",
    },
    {
      question:
        "How can I schedule a diagnostic/pathology appointment?",
      answer:
        "To schedule a diagnostic/pathology appointment one needs to go to the Diagnostics tab in the MyCLNQ app and enter the details of the test(s) required to be done and choose from among the available slots and confirm the request. A confirmation mail will be sent to the registered email ID of the customer and a phlebotomist will visit the customer on designated date and time to collect the sample(s)",
    },  {
      question:
        "What to do when products received from marketplace are damaged? ",
      answer:
        "After reciving the consignment  one needs to capture the opening of the consignment in a video and clearly capture the details on the label of the consignment. The video should also capture condition of each product received and highlight damages in the unedited and uninterrupted video. Thereafter the customer has to raise a complaint with MyCLNQ customer service and after due diligence replacement/refund will be initiated The complaint needs to be reported within 48 hrs of receipt of goods",
    },  {
      question:
        "How do I redeem earned cash coins? ",
      answer:
        "The accumulated cash coins can be redeemed through purchase of health and wellness products made on the marketplace in MyCLNQ app",
    },
    {
      question:
        "How do I renew my subscription? ",
      answer:
        "Subscription can be renewed by clicking on the subscription tab and choosing the desired subscription in the MyCLNQ app",
    },
    
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div
              className="faq-question"
              onClick={() => toggleAnswer(index)}
              role="button"
              aria-expanded={activeIndex === index}
              aria-controls={`answer-${index}`}
              id={`question-${index}`}
            >
              <span className="toggle-icon">
                {activeIndex === index ? "−" : "+"}
              </span>
              <h3>{faq.question}</h3>
            </div>
            {activeIndex === index && (
              <div
                id={`answer-${index}`}
                className="faq-answer"
                role="region"
                aria-labelledby={`question-${index}`}
              >
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
