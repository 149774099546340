export const MenuListArray2 = [
  {
    title: "HOME",
    to: "/",
  
  },
  {
    title: "SUBSCRIPTION",
    to: "/about-us",
   
  },
  {
    title: "CASH COINS",
    to: "/portfolio",
 
  },

  {
    title: "ESHOP",
    to: "/services",
  
  },
  {
    title: "TESTIMONIALS",
    to: "/blog-grid",
   
  },
  {
    title: "NEWS & ARTICLES",
    to: "/contact-us",
  },
  {
    title: "CONTACT",
    to: "/contact-us",
  },
];