import React, { useRef, useState } from "react";
import { IMAGES } from "../constants/theme";
import chkhAudio from "../assets/audio/ckhk.mp3";
import "react-modal-video/css/modal-video.min.css";
import "./AudioPlayer.css";
import { useNavigate } from "react-router-dom";
import freeWatch from "../assets/images/freeWatch.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const iconDropBlog = [
  { title: "7000 steps completed", title2: "Earned 150 Cash coins", id: "icon1" },
  { title: "Start tracking your wellness", title2: "goals today with MyCLNQ app…", title3: "and earn cash coins", id: "icon2" },
];

const MainBanner = ({ onVideoClick }) => {
  const [iconTitle, setIconTitle] = useState("icon1");
  const [isOpen, setOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const banners = [
    {
      img: IMAGES.sliderpic1,
      mediaClass: "slider-1",
      iconDropBlog: [
        { title: "7000 steps completed", title2: "Earned 150 Cash coins", id: "icon1" },
        { title: "Start tracking your wellness", title2: "goals today with MyCLNQ app…", title3: "and earn cash coins", id: "icon2" },
      ],
    },
    {
      img: IMAGES.sliderpic2,
      mediaClass: "slider-2",
      iconDropBlog: [
        { title: "Enjoy great discounts", title2:"on wellness products", id: "icon1" },
        { title: "Use cash coins to buy", title2:"everyday wellness products from", title3:"MyCLNQ wellness marketplace", id: "icon2" },
        // { id: "icon4", title: "First Banner Icon 2" },
      ],
    },
    {
      img: IMAGES.sliderpic3,
      mediaClass: "slider-3",
      iconDropBlog: [
        { title: "MyCLNQ Health", title2:"One stop solution for your ", title3:"primary healthcare needs", title4:"- FREE Online Doctor consultations", title5:"- FREE Diet consultations", title6:"- Great discounts on Medical products", title7:"- Upto 20% Off on Lab tests", id: "icon1" },
        // { id: "icon6", title: "First Banner Icon 2" },
      ],
    },
  ];

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVideoClick = () => {
    onVideoClick("https://www.youtube.com/embed/aHIPTvkDO5A?autoplay=1");
    setOpen(true);
  };

  const handleKnowMoreClick = () => {
    const faqSection = document.getElementById("FAQ");
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleWatchClick = () => {
    const watch = document.getElementById("perfect-trainers");
    if (watch) {
      watch.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="banner-inner" style={{ backgroundImage: `url(${IMAGES.SliderBg1})` }}>
        <div className="container">
          <div className="row banner-row  custom-banner">
            <div className="col-lg-6 col-md-7 col-sm-8  custom-banner-content">
              <div className="banner-content">
                <div className="top-content backdrop-blur">
                  <div style={{ paddingBottom: "30px" }}>
                    <img className="select_logo" src={IMAGES.logoNew} alt="Logo" />
                  </div>
                  <span>
                    {/* <span className="text-danger">Healthy Bharat Mission</span> -  */}
                    <span className="text-danger" style={{ fontSize: "22px" }}>
                      Get rewarded for completing your everyday wellness goals
                    </span>
                  </span>
                  <p className="mt-3">
                    Cash kar har kadam is an inspiring initiative by MyCLNQ to promote preventive wellness, where you get rewarded with cash coins for
                    completing your everyday wellness goals.
                  </p>
                  <div
                    className="d-flex align-items-center justify-content-between free-watch-div"
                    style={{
                      marginTop: "20px",
                    }}>
                    <div className="d-flex align-items-center flex-column custom-margin-buttons">
                      <button
                        onClick={handleVideoClick}
                        className="btn btn-primary btn-skew"
                        style={{
                          marginRight: "10px",
                          marginLeft: "2px",
                          marginTop: "10px",
                          width: "165px",
                        }}>
                        <i className="fa fa-play" aria-hidden="true" style={{ marginRight: "8px" }}></i>
                        Play Video
                      </button>
                      <button
                        className="btn btn-primary btn-skew"
                        style={{
                          marginRight: "10px",
                          marginTop: "50px",
                          width: "165px",
                        }}
                        onClick={handleKnowMoreClick}>
                        Know More
                      </button>
                    </div>
                    <div className="d-flex align-items-center flex-column custom-margin-buttons">
                      <button
                        className="btn btn-primary btn-skew"
                        style={{
                          marginRight: "10px",
                          marginLeft: "2px",
                          marginTop: "10px",
                          width: "165px",
                          textWrap: "nowrap",
                        }}
                        onClick={() => {
                          navigate("/get-app");
                        }}>
                        Get MyCLNQ App
                      </button>
                      <button
                        className="btn btn-primary btn-skew"
                        style={{
                          marginRight: "14px",
                          marginTop: "50px",
                          width: "165px",
                        }}
                        // onClick={handleKnowMoreClick}
                      >
                        <a style={{ color: "#FFFFFF" }} target="_blank" href="https://api.whatsapp.com/send?phone=917676045403">
                          {" "}
                          Chat with Us
                        </a>
                      </button>
                    </div>
                    <div className="d-flex flex-column free-watch-section mobile-free-watch custom-margin-buttons" style={{ alignItems: "center" }}>
                      <div className="download-image ">
                        <img
                          src={freeWatch}
                          alt="Icon"
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <span className="text-danger mobile-text download-image-span " style={{cursor:"pointer"}} onClick={handleWatchClick}>
                        FREE SmartWatch
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 col-sm-4" style={{padding:"0"}}>
              <Slider {...settings}>
                {banners.map((banner, index) => (
                  <div key={index}>
                    <div className={`${banner.mediaClass}`} style={{ position: "relative" }}>
                      <img src={banner.img} className="first-slide-image imgs" alt="" border="0" />
                      <ul className="point-list position-point">
                        {banner.iconDropBlog.map((item, ind) => (
                          <li
                            className={`icon-dropdown point-plus anm ${item.id === iconTitle ? "show" : ""}`}
                            data-speed-x="-1"
                            data-speed-scale="-1"
                            onClick={() => {
                              setIconTitle(item.id);
                              if (item.id === iconTitle) {
                                setIconTitle("");
                              }
                            }}
                            key={ind}>
                            <i className="fa-solid fa-plus"></i>
                            <span style={{textAlign:"start"}}>{item.title} 
                        {item?.title2  && (
                          <>
                          <br/>
                          {item.title2}
                          </>
                        )}
                        {item?.title3  && (
                          <>
                          <br/>
                          {item.title3}
                          </>
                        )}
                        {item?.title4  && (
                          <>
                          <br/>
                          {item.title4}
                          </>
                        )}{item?.title5  && (
                          <>
                          <br/>
                          {item.title5}
                          </>
                        )}{item?.title6  && (
                          <>
                          <br/>
                          {item.title6}
                          </>
                        )}{item?.title7  && (
                          <>
                          <br/>
                          {item.title7}
                          </>
                        )}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainBanner;
