import React, { useEffect } from "react";
import ErrorPage from "./ErrorPage";
import { Link, useParams } from "react-router-dom";
import { IMAGES } from "../constants/theme";
import NewsLetter from "../elements/NewsLetter";
import PageTitle from "../elements/PageTitle";
import LatestSlider from "../elements/LatestSlider";
import PressData from "../api/PressData";
import PressRelease from "../elements/PressRelease";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faXTwitter,
  faPinterest,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const PressDetails = () => {
  const { link } = useParams();
const newLink = "press/"+link;
  const blogPost = PressData.find((datablog) => datablog.link === newLink);
 

  if (!blogPost) {
    return <ErrorPage />;
  }

  const TextWithBold = (text) => {
    const textString = typeof text === "string" ? text : "";

    const boldedText = textString.split(/({{[^}]+}})/g).map((part, index) => {
      if (part.startsWith("{{") && part.endsWith("}}")) {
        const keyword = part.slice(2, -2);
        return (
          <strong key={index} style={{ fontWeight: "500" }}>
            {keyword}
          </strong>
        );
      }
      return part;
    });

    return <>{boldedText}</>;
  };

  const renderSection = (section, index) => {
    return (
      <div key={index} className="dz-post-text">
        {section.title && <h3>{section.title}</h3>}
        {section.description && <p>{TextWithBold(section.description)}</p>}
        {section?.description2 && (
          <p style={{ fontSize: "22px", fontWeight: "500" }}>
            {TextWithBold(section.description2)}
          </p>
        )}
        {section.content && Array.isArray(section.content) && (
          <ul className="m-b30">
            {section.content.map((item, idx) => (
              <li key={idx}>{TextWithBold(item)}</li>
            ))}
          </ul>
        )}
        {section.links &&
          Array.isArray(section.links) &&
          section.links.length > 0 && (
            <div className="links-section">
              <ul>
                {section.links.map((link, idx) => (
                  <>
                    <a
                      href={link}
                      key={idx}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link}
                    </a>
                    <br />
                  </>
                ))}
              </ul>
            </div>
          )}
      </div>
    );
  };

  const renderAdditionalContent = () => {
    if (!blogPost.additionalContent || !blogPost.additionalContent.sections) {
      return null;
    }

    return blogPost.additionalContent.sections.map((section, index) =>
      renderSection(section, index)
    );
  };

  return (
    <div className="page-content bg-white">
      <PageTitle activePage="Press Release" parentTitle="Home" />
      <section className="content-inner container-news-article">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="blog-single dz-card sidebar">
                <div className="dz-media article">
                  <img
                    src={blogPost.imageMain}
                    alt="Press Detail"
                    className="article-main-image article-Press-image"
                  />
                </div>
                {blogPost.additionalContent?.belowImgTitle && (
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "700",
                      fontSize: "1.875rem",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    {blogPost.additionalContent?.belowImgTitle}
                  </div>
                )}

                <div className="dz-info m-b30">
                  <div className="dz-meta">
                    <ul>
                      <li className="post-author">
                        <img src={IMAGES.avatar2} alt="Author" />
                      </li>

                      <li className="post-author">
                        <span>{blogPost.tag}</span>
                      </li>
                      <li className="post-date">
                        <span>{blogPost.date}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="Share-link">
                    <h5>{blogPost.additionalContent.share}</h5>
                    <div className="social-icons">
                      <a
                        href="https://www.facebook.com/profile.php?id=61563160937516"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faFacebook} size="1.5x" />
                      </a>
                      <a
                        href="https://x.com/myclnqindia"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faXTwitter} size="1.5x" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/myclnq-health-india/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faLinkedin} size="1.5x" />
                      </a>
                    </div>
                  </div>
                  <h2 className="dz-title dz-press">
                    {blogPost.additionalContent.title}
                  </h2>

                  <div className="dz-post-text">
                    <p>
                      {TextWithBold(blogPost.additionalContent.description)}
                    </p>
                    {renderAdditionalContent()}
                  </div>
                  <h2 className="dz-title dz-press">
                    {blogPost.additionalContent.title2}
                  </h2>
                  <div className="dz-post-text">
                    <p>
                      {TextWithBold(blogPost.additionalContent.description2)}
                    </p>
                    {renderAdditionalContent()}
                  </div>
                  <div className="dz-post-text">
                    <p>
                      {TextWithBold(blogPost.additionalContent.description3)}
                    </p>
                    {renderAdditionalContent()}
                  </div>
                  <h2 className="dz-title dz-press">
                    {blogPost.additionalContent.title3}
                  </h2>
                  <div className="dz-post-text">
                    <p>
                      {TextWithBold(blogPost.additionalContent.description4)}
                    </p>
                    {renderAdditionalContent()}
                  </div>
                  <h2 className="dz-title dz-press">
                    {blogPost.additionalContent.title4}
                  </h2>
                  <div className="dz-post-text">
                    <p>
                      {TextWithBold(blogPost.additionalContent.description5)}
                    </p>
                    {renderAdditionalContent()}
                  </div>
                  {/* one des */}
                  <div className="dz-post-text">
                    <i>
                      {TextWithBold(blogPost.additionalContent.description6)}
                    </i>
                    {renderAdditionalContent()}
                  </div>
                  {/* onedes */}
                  <h2 className="dz-title dz-press">
                    {blogPost.additionalContent.title5}
                  </h2>
                  <div className="dz-post-text">
                    <p>
                      {TextWithBold(blogPost.additionalContent.description7)}
                    </p>
                    {renderAdditionalContent()}
                  </div>
                  {/* <h2 className="dz-title dz-press">
                    {blogPost.additionalContent.title6}
                  </h2> */}
                  <div className="dz-post-text">
                    <p>
                      {TextWithBold(blogPost.additionalContent.description8)}
                    </p>
                    <p>
                      {TextWithBold(blogPost.additionalContent.description9)}
                    </p>
                    <p>
                      {TextWithBold(blogPost.additionalContent.description10)}
                    </p>
                    <p>
                      {TextWithBold(blogPost.additionalContent.description11)}
                    </p>
                    <p>
                      {TextWithBold(blogPost.additionalContent.description12)}
                    </p>
                    <p>
                      {TextWithBold(blogPost.additionalContent.description13)}
                    </p>
                    <p>
                      {TextWithBold(blogPost.additionalContent.description14)}
                    </p>
                    {renderAdditionalContent()}
                  </div>
                </div>
              </div>

              <section
                className="content-inner-1 overflow-hidden"
                style={{ backgroundImage: `url(${IMAGES.BgImage1})` }}
              >
                {/* <LatestSlider /> */}
                <PressRelease />
              </section>
            </div>
            <div className="col-xl-4 col-lg-4">
              {/* BlogSidebar Component can be added here */}
            </div>
          </div>
        </div>
      </section>

      <section className="call-action style-1 footer-action">
        <div className="container">
          <NewsLetter />
        </div>
      </section>
    </div>
  );
};

export default PressDetails;
