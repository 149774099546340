import React, { useRef, useState } from 'react';
import swal from 'sweetalert';

const NewsLetter = () => {
    const form = useRef();
    const [isLoading, setIsLoading] = useState(false);

    const subscribeNewsletter = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData(form.current);
        const email = formData.get('dzEmail');

        try {
            // const response = await fetch('https://stagingapi.myclnqhealth.com/api/v1/ckhk/subscribe_newsletters', {
            const response = await fetch('https://myclnqapi.ssivixlab.com/api/v1/ckhk/subscribe_newsletters', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            if (response.ok) {
                console.log('Subscription successful!');
                swal("Success", "Subscription successful!", "success");
                e.target.reset(); 
            } else {
                console.error('Subscription failed:', response.statusText);
                swal("Oops", "Subscription failed. Please try again.", "error");
            }
        } catch (error) {
            console.error('Error:', error);
            swal("Oops", "An error occurred. Please try again.", "error");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="inner-content wow fadeInUp" data-wow-delay="0.8s">
                <div className="row justify-content-between align-items-center">
                    <div className="text-center text-lg-start col-xl-6 m-lg-b20">
                        <h2 className="title">Subscribe To Our Newsletter</h2>
                    </div>
                    <div className="text-center text-lg-end col-xl-6">
                        <form className="dzSubscribe" ref={form} onSubmit={subscribeNewsletter}>
                            <div className="dzSubscribeMsg"></div>
                            <div className="form-group mb-0">
                                <div className="input-group mb-0"> 
                                    <div className="input-skew">
                                        <input 
                                            name="dzEmail" 
                                            required="required" 
                                            type="email" 
                                            className="form-control" 
                                            placeholder="Your Email Address" 
                                            disabled={isLoading}
                                        />
                                    </div>
                                    <div className="input-group-addon">
                                        <button 
                                            name="submit" 
                                            type="submit" 
                                            className="btn btn-secondary btn-lg btn-skew"
                                            disabled={isLoading}
                                        >
                                            <span>{isLoading ? 'Subscribing...' : 'Subscribe Now'}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>  
        </>
    );
};

export default NewsLetter;
