import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
const VideoModal = ({ isOpen, videoUrl, onClose }) => {
  return (
    <ModalVideo
      channel="custom"
      isOpen={isOpen}
      url={videoUrl}
      onClose={onClose}
    />
  );
};
export default VideoModal;