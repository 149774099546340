import React, { useState, useEffect } from "react";
import "./CustomSlider.css";

const CustomSlider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isLastToFirst, setIsLastToFirst] = useState(false); // Track last-to-first transition

  const prevSlide = () => {
    setIsTransitioning(true);
    setIsLastToFirst(false); // Reset the last-to-first flag
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const nextSlide = () => {
    if (currentSlide === slides.length - 1) {
      setIsLastToFirst(true); // Set the last-to-first flag
      setIsTransitioning(true);
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentSlide(0);
        setIsLastToFirst(false); 
      }, 500); 
    } else {
      setIsTransitioning(true);
      setCurrentSlide((prev) => prev + 1);
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // nextSlide();
  //   }, 6000); // Scroll automatically every 3 seconds

  //   return () => clearInterval(interval); 
  // }, [currentSlide]);

  return (
    <div className="custom-slider">
      <button className="slider-button prev arrow-mobile" onClick={prevSlide}>
        ‹
      </button>
      <div className="slides-container">
        <div
          className={`slides ${isTransitioning ? "transitioning" : ""} ${
            isLastToFirst ? "last-to-first" : ""
          }`}
          style={{
            transform: `translateX(-${currentSlide * 100}%)`,
          }}
        >
          {slides.map((slide, index) => (
            <div key={index} className="slide">
              {slide}
            </div>
          ))}
        </div>
      </div>
      <button className="slider-button next arrow-mobile" onClick={nextSlide}>
        ›
      </button>
    </div>
  );
};

export default CustomSlider;
