import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import NewsLetter from "../elements/NewsLetter";
import PageTitle from "../elements/PageTitle";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ContactUs = () => {
  const form = useRef();
  const [emailErr, setEmailErr]=useState('')
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === 'firstName' || name === 'lastName') {
      updatedValue = value.replace(/^\s+/, '');
    } else if (name === 'phoneNumber') {
  
      updatedValue = value.replace(/\D/g, ''); 
      if (updatedValue.length > 10) {
        updatedValue = updatedValue.slice(0, 10); 
      }
    }
    else if (name === 'email') {
      if (value.trim() === '') {
        // Clear the email error if the email field is empty
        setEmailErr('');
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.(com|net|org|edu)$/i;
        if (!emailRegex.test(value)) {
          setEmailErr('Invalid email format');
        } else {
          // Clear the email error if the email format is valid
          setEmailErr('');
        }
      }
    }
    setFormValues({
      ...formValues,
      [name]: updatedValue,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/; // Adjust this regex based on your phone number format
    return phoneRegex.test(phoneNumber);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if(emailErr){
      toast.error('email is invalid');
      setEmailErr('email is envalid');
    }

    if (!validatePhoneNumber(formValues.phoneNumber)) {
      swal("Invalid Phone Number", "Please enter a valid 10-digit phone number.", "error");
      return;
    }
    postData();
  };

  const postData = () => {
    const requestBody = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      phone: formValues.phoneNumber,
      message: formValues.message,
    };

    // fetch("https://stagingapi.myclnqhealth.com/api/v1/ckhk/send_message", {
    fetch("https://myclnqapi.ssivixlab.com/api/v1/ckhk/send_message", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data)
        swal("Success", "Your message has been sent!", "success");
        // Optionally clear the form
        setFormValues({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
      })
      .catch((error) => {
        swal("Oops", "Something went wrong. Please try again.", "error");
        console.error("There was an error sending the message:", error);
      });
  };

  return (
    <>
      <div className="page-content bg-white">
        <div className="container">
          <div className="row justify-content-between align-items-center m-b20">
            <div className="col-md-9 col-lg-8 col-xl-7">
              <div className="section-head text-center text-md-start">
                <h2 className="title">
                  Contact <span>Us</span>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <section className="content-inner-2 z-index-none pt-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-xl-5 m-sm-b30 m-xl-b0">
                <div className="contact-box">
                  <h3 className="contact-title">Contact Information</h3>
                  <p className="contact-text">
                    Fill up the form and our team will get back to you within 24 hours.
                  </p>
                  <div className="widget widget_getintuch">
                    <ul>
                      <li>
                      <i class="fa-brands fa-whatsapp" style={{color:"white"}}></i>                        <p>
                        <a
                          style={{ color: "#f5f5f5" }}
                          target="_blank"
                          href="https://api.whatsapp.com/send?phone=917676045403"
                          rel="noreferrer"
                        >
                         +91-7676 045 403
                        </a>
                        </p>
                      </li>
                      <li>
  <i className="fa-solid fa-envelope" style={{ color: "white" }}></i>
  <p>
    <a href="mailto:support@myclnq.co.in" style={{ color: "white", textDecoration: "none" }}>
      support@myclnq.co.in
    </a>
  </p>
</li>

                    </ul>
                  </div>
                  <h6 className="m-b15 text-dark">Our Socials</h6>
                  <div className="dz-social-icon style-1">
                    <ul>
                      <li>
                        <Link
                          target="_blank"
                          to="https://www.facebook.com/profile.php?id=61563160937516"
                          rel="noreferrer"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          target="_blank"
                          to="https://x.com/myclnqindiaindia"
                          rel="noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            id="Layer_1"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            style={{ enableBackground: 'new 0 0 24 24' }}
                            xmlSpace="preserve"
                          >
                            <path
                              d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717 l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339 l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z"
                              fill="white"
                            />
                            </svg>
                        </Link>
                      </li>
                      <li>
                        <Link
                          target="_blank"
                          to="https://www.instagram.com/myclnqindia/"
                          rel="noreferrer"
                        >
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <a
                          style={{ color: "#f5f5f5" }}
                          target="_blank"
                          href="https://api.whatsapp.com/send?phone=917676045403"
                          rel="noreferrer"
                        >
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>
                      <li>
                        <Link
                          target="_blank"
                          to="https://www.linkedin.com/company/myclnq-health-india/"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <svg
                    className="svg2"
                    width="250"
                    height="70"
                    viewBox="0 0 250 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 38L250 0L210 70L0 38Z"
                      fill="url(#paint0_linear_306_1296)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_306_1296"
                        x1="118.877"
                        y1="35.552"
                        x2="250.365"
                        y2="35.552"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="1" stopColor="var(--primary)" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
              <div className="col-md-6 col-xl-7">
                <form
                  className="dz-form dzForm style-1"
                  ref={form}
                  onSubmit={sendEmail}
                >
                  <input
                    type="hidden"
                    className="form-control"
                    name="dzToDo"
                    value="Contact"
                  />
                  <div className="dzFormMsg"></div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-group input-line">
                        <input
                          name="firstName"
                          required
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={formValues.firstName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group input-line">
                        <input
                          name="lastName"
                          required
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={formValues.lastName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="input-group input-line">
                        <input
                          name="email"
                          required
                          type="email"
                          className="form-control"
                          placeholder="Your Email Address"
                          value={formValues.email}
                          onChange={handleChange}
                        />
                      </div>
                      {emailErr && <p className="text-danger" style={{marginTop:"-2.2rem"}}>{emailErr}</p>}
                    </div>
                    <div className="col-xl-6">
                      <div className="input-group input-line">
                        <input
                          name="phoneNumber"
                          required
                          type="tel"
                          className="form-control"
                          placeholder="Phone"
                          value={formValues.phoneNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="input-group input-line m-b30">
                        <textarea
                          style={{ height: "3.7rem" }}
                          name="message"
                          rows="5"
                          required
                          className="form-control"
                          placeholder="Message..."
                          value={formValues.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <button
                        name="submit"
                        type="submit"
                        value="Submit"
                        className="btn btn-primary btn-lg btn-skew mt-3"
                      >
                        <span>Send Message</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner subscribe-wraper-1">
          <div className="container">
            <NewsLetter />
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUs;
