import React from "react";
import { IMAGES } from "../constants/theme";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

const clientLogo = [
  // { img: 'https://www.blumorph.com/images/logo-bluemorpho.jpg' },
  { img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCSI2O88_FXWaJuSAu1IOTMhRz6pkS6_kdIg&s' },
  { img: 'https://colatch.com/wp-content/webp-express/webp-images/uploads/elementor/thumbs/LOGO-100-1-qm90oli93yk8mr5r7v54y58jdgl61uuo3rdyoplt6o.png.webp' },
  { img: "https://uploadimage.io/images/2024/08/03/redclief-logo.png" },
  { img: "https://uploadimage.io/images/2024/08/03/mirchi-log.jpeg" },
  { img: "https://socialdonut.co/wp-content/uploads/2020/01/SD-Logo.png" },

];
const Clients = () => {
  return (
    <>
      <div className="swiper clients-swiper">
        <Swiper
          slidesPerView={4}
          modules={[Autoplay]}
          speed={1500}
          loop={true}
          autoplay={{ delay: 100 }}
          breakpoints={{
            991: { slidesPerView: 4 },
            775: { slidesPerView: 3 },
            575: { slidesPerView: 2 },
            240: { slidesPerView: 2 },
          }}
          className="swiper-wrapper"
        >
          {clientLogo.map((item, ind) => (
            <SwiperSlide className="swiper-slide wow fadeInUp" key={ind}>
              <div className="clients-logo">
                <img src={item.img} alt="" style={{padding:"5px"}}/>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Clients;
