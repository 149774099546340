import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';

const dataBlog = [
  {
    image: "https://img.freepik.com/free-vector/sport-fitness-tracker-abstract-concept-vector-illustration_335657-6356.jpg?t=st=1722078843~exp=1722082443~hmac=7248ee352a3d196c580eb39870ab76cd52f4d87f7bd05f6953f10d25ab46cf46&w=740",
    title: "Smart Watch",
    price:'INR 1299',
    changestyle: "box-3",
  },
  {
    image: "https://img.freepik.com/free-photo/top-view-tensiometer-checking-blood-pressure_23-2150456073.jpg?ga=GA1.2.522273382.1721993206&semt=ais_user",
    title: "Blood Pressure Machine",
    price:'INR 1599',
    changestyle: "box-3",
  },
  {
    image: 'https://cdn.nuawoman.com/global/img/PAD-COT-3PR-06-22/sanitary-pads_28Mar_1.png',
    title: "Sanitary Pads",
    price:'INR 199',
    changestyle: "box-3",
  },
  {
    image: "https://img.freepik.com/free-vector/n95-surgical-mask-with-layers_52683-42714.jpg?ga=GA1.2.522273382.1721993206&semt=ais_user",
    title: "Protective Mask",
    price:'INR 299',
    changestyle: "box-3",
  },
  {
    image: "https://img.freepik.com/free-vector/type-coronavirus-test_52683-38541.jpg?ga=GA1.2.522273382.1721993206&semt=ais_user",
    title: "Medical Testing Kit",
    price:'INR 2999',
    changestyle: "box-3",
  },
];

function WorkoutSlider() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);

  return (
    <>
      <h2 className="title wow fadeInUp" style={{ textAlign: "center", fontSize: '35px',paddingTop:"140px",color:"#ffffff" }}>WELLNESS &#160;<span style={{color:"#ff4848"}}>MARKETPLACE</span></h2>
      <p style={{textAlign:'center', marginBottom: '5rem', color:'white'}}>The more steps you take, and the greater the rewards.</p>
      <Swiper
        className="swiper portfolio-slider"f
        slidesPerView={"auto"}
        spaceBetween={0}
        loop={true}
        speed={1500}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' + className + '"> 0' + (index + 1) + "</span>"
            );
          },
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.navigation.destroy();
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {dataBlog.map((item, ind) => (
          <SwiperSlide key={ind}>
            <div className={`dz-box style-1 ${item.changestyle} bazzar-head`}>
              <div className="dz-media">
                <Link to={"/"}>
                  <img className="bazaar-img" src={item.image} alt="" />
                </Link>
              </div>
              <div className="dz-info bazzar-absolute">
                <h3 className="title">
                  <Link to={"/"}>{item.title}</Link>
                </h3>
                <h5 style={{ color: '#fff' }}>Price:{item.price}</h5>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="container">
          <div className="num-pagination">
            <div
              className="portfolio-button-prev btn-prev dark"
              ref={navigationPrevRef}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </div>
            <div
              className="swiper-pagination dark style-1 m-lr-lg"
              ref={paginationRef}
            ></div>
            <div
              className="portfolio-button-next btn-next dark"
              ref={navigationNextRef}
            >
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </Swiper>
    </>
  );
}

export default WorkoutSlider;
