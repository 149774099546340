import React from 'react'
import './DiwaliBanner.css'
import diwaliOffer from "../assets/images/diwali-offer.png"
import { useNavigate } from 'react-router-dom'
// import diwaliBanner from  "../assets/images/diwaliBanner.png"
import diwaliWinner from  "../assets/images/diwaliWinner.png"
const DiwaliBanner = () => {
    const navigate=useNavigate()
  return (
    <div className='diwali-banner-main'><img src={diwaliWinner} alt="" /></div>
  )
}

export default DiwaliBanner