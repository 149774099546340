import React, { useState, useEffect, useReducer } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { IMAGES } from "../constants/theme";
import Collapse from "react-bootstrap/Collapse";
import "./header.css";
import qr from "../assets/success/scanMe.jpg";

const MenuListArray2 = [
  { title: "HOME", to: "/", to1: "/" },
  { title: "CASH COINS", to: "#clients", to1: "#clients" },
  { title: "MEMBERSHIP", to: "#perfect-trainers", to1: "#perfect-trainers" },
  { title: "MARKETPLACE", to: "#counter-blog", to1: "#counter-blog" },
  // { title: "TESTIMONIALS", to: "#fitness-goal" },
  { title: "NEWS & ARTICLES", to: "/news-articles", to1: "#fitness-ideas" },
  // {title: "Press Release", to: "/press-release", to1: "#press-idea"},
  { title: "CONTACT", to: "#workout-slider", to1: "#workout-slider" },
];

export const Mainheader = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const mainMenu = document.getElementById("OpenMenu");
    if (mainMenu) {
      mainMenu.classList.toggle("show", sidebarOpen);
    }
  }, [sidebarOpen]);

  const reducer = (previousState, updatedState) => ({
    ...previousState,
    ...updatedState,
  });

  const initialState = { active: "", activeSubmenu: "" };
  const [state, setState] = useReducer(reducer, initialState);

  const handleMenuActive = (status) => {
    setState({ active: state.active === status ? "" : status });
  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: state.activeSubmenu === status ? "" : status });
  };

  // Update active menu based on location hash
  useEffect(() => {
    const currentHash = location.hash;
    const activeItem = MenuListArray2.find((item) => item.to === currentHash);
    if (activeItem) {
      setActiveMenu(activeItem.title);
    }
  }, [location.hash]);

  // Set up Intersection Observer to detect which section is in view
  useEffect(() => {
    const resetPaths = ["/get-app", "/upcoming"];

    if (resetPaths.includes(location.pathname)) {
      setActiveMenu(null);
      // return;
    }
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: [0.4, 0.6, 1.0], // Adjusted threshold for better accuracy
    };

    const observer = new IntersectionObserver((entries) => {
      // console.log(entries);
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveMenu(MenuListArray2.find((item) => item.to1 === `#${entry.target.id}`)?.title || "");
        } else if (entry.boundingClientRect.top > 0) {
          // Special case for the home section when scrolling up
          setActiveMenu(MenuListArray2[0].title);
        }
      });
    }, observerOptions);

    MenuListArray2.forEach((item) => {
      if (item.to1.startsWith("#")) {
        const section = document.querySelector(item.to1);
        if (section) {
          observer.observe(section);
        }
      }
    });

    return () => {
      MenuListArray2.forEach((item) => {
        if (item.to1.startsWith("#")) {
          const section = document.querySelector(item.to1);
          if (section) {
            observer.unobserve(section);
          }
        }
      });
    };
  }, [location.pathname]);

  const scrollToSection = (id) => {
    if (id.startsWith("#")) {
      const element = document.querySelector(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  useEffect(() => {
    const sectionElements = MenuListArray2.map((item) => (item.to.startsWith("#") ? document.querySelector(item.to) : null)).filter(
      (element) => element !== null
    );

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: [0.6, 1.0], // Adjusted threshold for better accuracy
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const activeItem = MenuListArray2.find((item) => item.to === `#${entry.target.id}`);
          if (activeItem) {
            console.log(activeItem);
            setActiveMenu(activeItem.title);
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sectionElements.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      sectionElements.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  const [headerFix, setHeaderFix] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setHeaderFix(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const homeItem = { title: "HOME", to: "/", to1: "/" };

  const handleNavigation = (e, homeItem) => {
    e.preventDefault();
    setActiveMenu(homeItem.title); // Set the clicked item as active

    if (homeItem.title === "HOME") {
      if (window.location.pathname === "/") {
        console.log("111111");
        navigate("/");
        window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the home page
      } else {
        console.log("111111");
        navigate("/"); // Navigate to the home page first
        setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 500); // Scroll to the top after navigation
      }
    } else if (homeItem.to.startsWith("#")) {
      if (window.location.pathname === "/") {
        scrollToSection(homeItem.to); // Scroll to section if on the home page
      } else {
        navigate("/"); // Navigate to the home page first
        setTimeout(() => scrollToSection(homeItem.to), 500); // Scroll to section after navigation
      }
    } else {
      navigate(homeItem.to); // Navigate to the correct page
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const mainMenu = document.getElementById("navbarNavDropdown");
      const hamburgerButton = document.querySelector(".navbar-toggler");
      if (mainMenu && !mainMenu.contains(event.target) && !hamburgerButton.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={`main-bar clearfix ${headerFix ? "is-fixed" : ""}`}>
      <div className={`custom-navbar ${headerFix ? "nav-fixed" : ""}`}>
        <div className="logo-header mostion logo-dark" onClick={(e) => handleNavigation(e, homeItem)}>
          <img className="select_logo" src={IMAGES.logo} alt="Logo" />
        </div>
        <div className={`nav-buttons fixed-bottom-navbar`}>
          <div
            className="qr-box"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/get-app");
            }}>
            {/* <p>Scan &<br/>Download</p> */}
            <img src={qr} className="qr-image-header" />
          </div>
          <button className={`fixed-bot-button-l new-button-border2`}>
            <a
              style={{
                color: "#25D366",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
              target="_blank"
              href="https://api.whatsapp.com/send?phone=917676045403">
              <i className="fas fa-comment" style={{ marginRight: "10px", color: "#FF4848" }}></i>
              <span style={{ color: "#000000", fontSize: "15px", fontWeight: "500" }} className="text-danger">
                Chat with Us
              </span>
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  const [headerFix, setHeaderFix] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      setHeaderFix(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className={`site-header mo-left header header-transparent style-1 ${headerFix ? "is-fixed" : ""}`}>
      <div className="sticky-header main-bar-wraper navbar-expand-lg">
        <Mainheader />
      </div>
    </header>
  );
};

export default Header;
