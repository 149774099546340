import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ModalVideo from "react-modal-video";
import LatestSlider from "../elements/LatestSlider";
import "react-modal-video/css/modal-video.min.css";

import ClientSlider from "../components/ClientSlider";
import FitnessIdeas from "../components/FitnessIdeas";
import CounterBlog from "../components/CounterBlog";
import FitnessGoal from "../components/FitnessGoal";
import FAQ from "../components/FAQ";

import MainBanner from "../components/MainBanner";
import NewMainBanner from "../components/newMainBanner";
import MainBanner2 from "../components/MainBanner2";
import MainBanner4 from "../components/MainBanner4";
import MainBanner5 from "../components/MainBanner5";
import PerfectTrainers from "../components/PerfectTrainers";
import Clients from "../elements/Clients";
import { IMAGES } from "../constants/theme";
import NewsLetter from "../elements/NewsLetter";
import WorkoutSlider from "../elements/WorkoutSlider";
import PressRelease from "../elements/PressRelease";

import CustomSlider from "./CustomSlider";
import ContactUs from "./ContactUs";
import VideoSlider from "../elements/VideoSlider";
import "./Home.css"
import DiwaliBanner from "./DiwaliBanner";

const Home = () => {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation().pathname;
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const body = document.querySelector("body");
    body.setAttribute("data-theme-color", "color_1");
    localStorage.setItem("theme", "color_1");
    localStorage.setItem("themeInd", 0);
  }, [location]);

  const handleVideoOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
  };

console.log("hhhhhhhhhhhhh")
  const slides = [
    <div className="slide" key="1">
      <NewMainBanner  onVideoClick={handleVideoOpen} />
    </div>,
    // <div className="slide" key="3">
    //   <MainBanner4 onVideoClick={handleVideoOpen} />
    // </div>,
    // <div className="slide" key="2">
    //   <MainBanner2 onVideoClick={handleVideoOpen} />
    // </div>,
    // <div className="slide" key="4">
    //   <MainBanner5 onVideoClick={handleVideoOpen} />
    // </div>,
  ];

  return (
    <>
      <div className="page-content bg-white">
        <div className="main-bnr-one" id="home">
          <CustomSlider slides={slides} />
        </div>
        <section
          id="clients"
          // style={{height:"18rem"}}
        >
         
              <DiwaliBanner/>
           
        </section>
        <section
          className="content-inner about-wrapper1 about-bx1"
          id="clients"
        >
          <div className="container">
            <div className="row align-items-center">
              <PerfectTrainers />
            </div>
          </div>
        </section>
        <section>
          <div
            className="half-shape-top-w theme-bg content-inner-1"
            style={{ paddingTop: "50px" }}
          >
            <div className="container">
              <h2
                className="sub-title wow fadeInUp"
                data-wow-delay="0.2s"
                style={{
                  textAlign: "center",
                  paddingBottom: "80px",
                  paddingTop: "0px",
                }}
              >
                Our Partners
              </h2>
              <div className="clients-box">
                <Clients />
              </div>
              <div
                className="sub-title wow fadeInUp responsive-text"
                data-wow-delay="0.2s"
              >
                <span className="black-text">Choose Any</span>{" "}
                <span className="red-text">2 Goals</span>
              </div>
            </div>
          </div>
        </section>
        <section className="counter-wrapper1" style={{ paddingTop: "30px" }}>
          <div className="container">
            <div className="counter-inner bg-dark">
              <div className="row">
                <CounterBlog />
              </div>
            </div>
          </div>
        </section>
        <section
          id="perfect-trainers"
          className="content-inner overflow-hidden"
          style={{
            backgroundImage: "url(" + IMAGES.BgImage1 + ")",
            marginTop: "100px",
          }}
        >
          <div className="container">
            <div className="row justify-content-between align-items-center m-b20">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="section-head side-line">
                  <h5
                    className="sub-title wow fadeInUp text-danger"
                    data-wow-delay="0.2s"
                  >
                    Healthy Bharat Mission
                  </h5>
                  <h2 className="title wow fadeInUp" data-wow-delay="0.4s">
                    Join Healthy Bharat Mission
                  </h2>
                </div>
              </div>
              <div
                className="col-md-3 d-none d-md-block text-md-end wow fadeInUp"
                data-wow-delay="0.6s"
              ></div>
            </div>
            <FitnessIdeas />
          </div>
        </section>
        <section id="counter-blog" className="content-inner portfolio-wrapper">
          <div className="portfolio-wrapper-inner">
            <div className="container-fluid p-0">
              <WorkoutSlider />
            </div>
          </div>
        </section>
        <section
          className="content-inner about-wrapper2"
          style={{
            backgroundImage: "url(" + IMAGES.BgImage3 + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row about-bx2 align-items-center">
              <FitnessGoal isOpenModal={setOpen} />
            </div>
          </div>
        </section>
        <section
          id="fitness-ideas"
          className="content-inner-1 overflow-hidden"
          style={{
            backgroundImage: "url(" + IMAGES.BgImage1 + ")",
            paddingTop: "90px",
            paddingBottom: "40px",
          }}
        >
          <PressRelease />
        </section>
        <section
          id="fitness-goal"
          className="content-inner-1 testimonial-wrapper1"
          data-text="FEEDBACK"
          style={{
            backgroundImage: "url(" + IMAGES.BgImage2 + ")",
            backgroundPosition: "center",
            paddingTop: "150px",
          }}
        >
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title wow fadeInUp" data-wow-delay="0.4s">
                Let's hear from{" "}
                <span className="color-red">
                  Healthy Bharat Mission members
                </span>
              </h2>
            </div>
            <ClientSlider />
          </div>
        </section>
        <section id="counter-blog" className="content-inner portfolio-wrapper">
          <div className="portfolio-wrapper-inner">
            <div className="container-fluid p-0">
              <VideoSlider />
            </div>
          </div>
        </section>
        <div id="FAQ" style={{ height: "20px" }}></div>
        <section className="section">
          <div className="container">
            <div className="section-head1 text-center text-md-start">
              <h2 className="title">
                FREQUENTLY <span>ASKED QUESTIONS</span>
              </h2>
            </div>
            <FAQ />
          </div>
        </section>

        <section
          id="fitness-ideas"
          className="content-inner-1 overflow-hidden"
          style={{
            backgroundImage: "url(" + IMAGES.BgImage1 + ")",
            paddingTop: "90px",
            paddingBottom: "40px",
          }}
        >
          <LatestSlider />
        </section>

        <section
          id="workout-slider"
          className="call-action style-1 footer-action contact"
        >
          <div className="container">
            <ContactUs />
          </div>
        </section>
        <section id="contact us">
          <div className="container"></div>
        </section>
      </div>
      <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen}
        url={videoUrl}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default Home;
