import React from 'react';
import {Link} from 'react-router-dom';
import {Tab, Nav} from 'react-bootstrap';
import { IMAGES } from '../constants/theme';

const FitnessGoal = ({isOpenModal}) => {
    return (
        <>
            <div className="col-lg-6 about-content m-b30">
                <div className="section-head m-0">
                    <span className="sub-title">ABOUT US</span>
                    <h2 className="title">MyCLNQ<span> Health</span></h2>
                    <p className="m-0">We believe adopting a healthy lifestyle is not only about improving your well-being but also about rewarding yourself in exciting new ways. Imagine earning cash coins every time you make a healthy choice, whether it's going for a run, eating a nutritious meal, or getting a good night's sleep. By accumulating cash coins, you can see the direct benefits of your efforts, motivating you to continue your journey towards a healthier, happier you. Embrace the challenge, earn rewards, and transform your life—one healthy choice at a time.</p>
                </div>
                <div className="" data-wow-delay="0.8s">
                    <Tab.Container defaultActiveKey={'Mission'}>
                        <Nav as="ul" className="nav nav-tabs style-1 m-b20 m-t30">
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link className="nav-link" eventKey={'Mission'}>
                                    <span>Our Mission</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link className="nav-link" eventKey={'Vision'}>
                                    <span>Our Vision</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="tab-content m-sm-b30 m-b40 p-r30" id="myTabContent">
                            <Tab.Pane eventKey={'Mission'}>
                                <div className="content">
                                    <p>At our company, our mission is to inspire and empower Bharatwasi to lead healthier, more fulfilling lives. We believe that a healthy lifestyle is the foundation for overall well-being and happiness. By fostering a culture of health and wellness, we aim to transform bharatwasi lives, one healthy choice at a time, and build a brighter Bharat, more vibrant future Bharat for all.</p>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey={'Vision'}>
                                <div className="content">
                                    <p>Our vision is to create a Bharat where healthy living is the norm and everyone has the opportunity to thrive. We aspire to be the leading force in promoting healthy lifestyle, making it an integral part of bharatwasi life. Together, we aim to transform our society into one where health and happiness are accessible to all.  JAI HIND !</p>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
            <div className="col-lg-6 m-b30">
                <div className="dz-media">
                    <div className="image-box">
                        <div className="video-bx1 h-auto w-auto overflow-visible">
                            <img src='https://th.bing.com/th/id/OLC.KDBzg5tEPTTIkg480x360?&rs=1&pid=ImgDetMain' alt="" />
                            <div className="video-btn sm">
                                {/* <Link to={"#"} className="popup-youtube" 
                                    onClick={()=> isOpenModal(true)} >
                                    <i className="fa fa-play"/>
                                </Link>  */}
                            </div>
                        </div>
                        <div className="info-box">
                            <span><i className="flaticon-athletics text-primary"></i>  Health and fitness</span>							
                        </div>
                    </div>
                    <div className="image-box">
                        <img src='https://th.bing.com/th/id/OLC.u8umHFe12XE6RA480x360?&rs=1&pid=ImgDetMain' alt="" />
                        <div className="info-box">
                            <span><i className="flaticon-athletics text-primary"></i> Cash coins for health</span>
                        </div>
                    </div>
                </div>
            </div>
                    
        </>
    );
};

export default FitnessGoal;