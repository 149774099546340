  import React, { useState } from "react";
  import DietForm from "./dietForm";
  const Home = () => {

    return (
      <>
        <div className="page-content bg-white main-diet-section">
          <div className="main-bnr-one" id="home">
            <DietForm />  
          </div>
          </div>
      </>
    );
  };

  export default Home;
