import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import dataBlogs from "../api/data";

function LatestSlider() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);

  return (
    <div className="container">
      <div className="row justify-content-between align-items-center m-b10">
        <div className="col-xl-7">
          <div className="section-head text-center text-md-start">
            <h2 className="title">
              News <span>And Articles</span>
            </h2>
          </div>
        </div>
        <div className="col-xl-5 text-md-end d-flex align-items-center justify-content-xl-end justify-content-sm-between justify-content-center m-sm-b30 m-b40">
          <div className="num-pagination">
            <div className="swiper-blog-prev btn-prev" ref={navigationPrevRef}>
              <i className="fa-solid fa-arrow-left"></i>
            </div>
            {/* <div
              className="swiper-blog-pagination1 swiper-pagination style-1"
              ref={paginationRef}
            ></div> */}
            <div className="swiper-blog-next btn-next" ref={navigationNextRef}>
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        className="swiper blog-slider-full blog-slider-wrapper"
        centeredSlides={true}
        slidesPerView={"auto"}
        spaceBetween={30}
        loop={true}
        speed={1500}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          el: ".swiper-blog-pagination1",
          clickable: true,
          renderBullet: (index, className) =>
            `<span class="${className}"> 0${index + 1}</span>`,
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.navigation.destroy();
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          320: {
            slidesPerView: 1,
            centeredSlides: false,
          },
        }}
      >
        {dataBlogs.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="dz-card style-1 overlay-shine">
              <div className="dz-media">
                <Link to={`/${item.link}`}>
                  <img src={item.image} alt={item.title} />
                </Link>
              </div>
              <div className="dz-info">
                <div className="dz-meta">
                  <ul>
                    <li className="post-author">
                      <span>{item.tag}</span>
                    </li>
                    <li className="post-date">
                      <span>{item.date}</span>
                    </li>
                  </ul>
                </div>
                <h4 className="dz-title">
                  <Link to={`/${item.link}`}>{item.title}</Link>
                </h4>
                <p>{item.content}</p>
                <Link to={`/${item.link}`} className="btn btn-primary btn-skew">
                  <span>Read More</span>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default LatestSlider;
