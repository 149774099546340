import React, { useState } from "react";
import "./NewMainBanner.css";
import { IMAGES } from "../constants/theme";
import { useNavigate } from "react-router-dom";
import freeWatch from "../assets/images/freeWatch.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import diwali from "../assets/images/diwali-img.png"
import diwaliNew from "../assets/images/new-diwali.png"

const NewMainBanner = ({ onVideoClick }) => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [iconTitle, setIconTitle] = useState("icon1");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  const banners = [
    {
      img: IMAGES.sliderpic1,
      mediaClass: "slider-1",
      iconDropBlog: [
        { title: "7000 steps completed", title2: "Earned 150 Cash coins", id: "icon1" },
        // { title: "Start tracking your wellness", title2: "goals today with MyCLNQ app…", title3: "and earn cash coins", id: "icon2" },
      ],
    },
    {
      img: IMAGES.sliderpic2,
      mediaClass: "slider-2",
      iconDropBlog: [
        { title: "Enjoy great discounts", title2: "on wellness products", id: "icon1" },
        // { title: "Use cash coins to buy", title2: "everyday wellness products from", title3: "MyCLNQ wellness marketplace", id: "icon2" },
      ],
    },
    {
      img: IMAGES.sliderpic3,
      mediaClass: "slider-3",
      iconDropBlog: [
        {
          title: "MyCLNQ Health",
          title2: "One stop solution for your ",
          title3: "primary healthcare needs",
          title4: "- FREE Online Doctor consultations",
          title5: "- FREE Diet consultations",
          title6: "- Great discounts on Medical products",
          title7: "- Upto 20% Off on Lab tests",
          id: "icon1",
        },
      ],
    },
  ];

  const handleVideoClick = () => {
    onVideoClick("https://www.youtube.com/embed/aHIPTvkDO5A?autoplay=1");
    setOpen(true);
  };

  const handleKnowMoreClick = () => {
    const faqSection = document.getElementById("FAQ");
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleWatchClick = () => {
    const watch = document.getElementById("perfect-trainers");
    if (watch) {
      watch.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${IMAGES.SliderBg1})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
      className="main-container-box">
      <div className="container banner-container">
        <div className="left-banner">
          <img className="select_logos" src={IMAGES.logoNew} alt="Logo" />
          <div className="">
            <span className="text-danger" style={{ fontSize: "22px" }}>
              Get rewarded for completing your everyday wellness goals
            </span>
          </div>
          <div className="">
            <p className="mt-3">
              Cash kar har kadam is an inspiring initiative by MyCLNQ to promote preventive wellness, where you get rewarded with cash coins for
              completing your everyday wellness goals.
            </p>
          </div>
          <div className="watch-button">
            <div className="button-container">
              <button onClick={handleVideoClick} className="new-button-border">
                <i className="fa fa-play" aria-hidden="true" style={{ marginRight: "8px" }}></i>
                Play Video
              </button>
              <button className="new-button-border" onClick={handleKnowMoreClick}>
                Know More
              </button>
              <button
                className="new-button-border "
                onClick={() => {
                  navigate("/get-app");
                }}>
                Get App
              </button>
              <button className="new-button-border ">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=917676045403">
                  Chat with Us
                </a>
              </button>
            </div>

            <div className="watch-container left-watch-box" onClick={handleWatchClick}>
              <img src={freeWatch} alt="Icon" />
              <div className="free-watch-title text-danger">SmartWatch FREE <br/>With Subscription</div>
            </div>
          </div>
        </div>
        <div className="right-banner">
          {/* <img src={diwaliNew} className="diwali-image" onClick={()=>navigate('/diwali-page')}/> */}
          <Slider {...settings}>
            {banners.map((banner, index) => (
              <div key={index}>
                <div className={`${banner.mediaClass}`} style={{ position: "relative" }}>
                  <img src={banner.img} className="first-slide-image imgs" alt="" border="0" />
                  <ul className="point-list position-point">
                    {banner.iconDropBlog.map((item, ind) => (
                      <li
                        className={`icon-dropdown point-plus anm ${item.id === iconTitle ? "show" : ""}`}
                        data-speed-x="-1"
                        data-speed-scale="-1"
                        // onClick={() => {
                        //   setIconTitle(item.id);
                        //   if (item.id === iconTitle) {
                        //     setIconTitle("");
                        //   }
                        // }}
                        key={ind}>
                        <i className="fa-solid fa-plus"></i>
                        <span style={{ textAlign: "start" }}>
                          {item.title}
                          {item?.title2 && (
                            <>
                              <br />
                              {item.title2}
                            </>
                          )}
                          {item?.title3 && (
                            <>
                              <br />
                              {item.title3}
                            </>
                          )}
                          {item?.title4 && (
                            <>
                              <br />
                              {item.title4}
                            </>
                          )}
                          {item?.title5 && (
                            <>
                              <br />
                              {item.title5}
                            </>
                          )}
                          {item?.title6 && (
                            <>
                              <br />
                              {item.title6}
                            </>
                          )}
                          {item?.title7 && (
                            <>
                              <br />
                              {item.title7}
                            </>
                          )}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </Slider>

          <div className="watch-container right-watch-box">
            <img src={freeWatch} alt="Icon" />
            <div className="free-watch-title text-danger" onClick={handleWatchClick}>
              SmartWatch FREE<br/>With Subscription
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMainBanner;
