import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import dataBlogs from "../api/data";
import PageTitle from "../elements/PageTitle";
import { IMAGES } from "../constants/theme";

function LatestSlider() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);

  return (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          backgroundImage: `url(${IMAGES.BgAppoint})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100vw",
        }}
      >
        <div style={{ position: "relative", top: "4rem" }}>
          <PageTitle activePage="News & Articles" parentTitle="Home" />
        </div>
      </div>

      <div className="container " style={{marginTop:"8rem"}}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6" style={{display:"flex",flexWrap:"wrap", gap:"1rem" }}>
          {dataBlogs.map((item, index) => (
            <div key={index} className="dz-card style-1 overlay-shine " style={{marginBottom:"6rem", width:"22rem",}}>
              <div className="dz-media">
                <Link to={`/${item.link}`}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-auto"
                  />
                </Link>
              </div>
              <div className="dz-info p-4">
                <div className="dz-meta mb-2">
                  <ul className="flex space-x-4">
                    <li className="post-author">
                      <span className="text-sm text-gray-500">{item.tag}</span>
                    </li>
                    <li className="post-date">
                      <span className="text-sm text-gray-500">{item.date}</span>
                    </li>
                  </ul>
                </div>
                <h4 className="dz-title text-xl font-semibold mb-2">
                  <Link
                    to={`/${item.link}`}
                    className="text-gray-900 hover:text-indigo-600"
                  >
                    {item.title}
                  </Link>
                </h4>
                <p className="text-gray-700 mb-4">{item.content}</p>
                <Link
                  to={`/${item.link}`}
                  className="btn btn-primary btn-skew px-4 py-2 bg-indigo-600 text-white  hover:bg-indigo-700"
                >
                  <span>Read More</span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LatestSlider;
