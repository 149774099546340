import React from 'react';
import CountUp from 'react-countup';

const counterData = [
    { number: 7000, title: "Steps Daily", styleChange: "col-md-3" },
    { number: 6, title: "Hours Daily Sleep", styleChange: "col-md-3" },
    { number: 4, title: "Hours Screen Time", styleChange: "col-md-3" },
    { number: 5, title: 'Floors Climbing', styleChange: "col-md-3" }
];

const CounterBlog = () => {
    return (
        <div className="row">
            {counterData.map((item, ind) => (
                <div className={`m-b30 ${item.styleChange}`} key={ind}>
                    <div className="counter-box">
                        <h3 className="title counter">
                            <CountUp end={item.number} duration={8} />
                        </h3>
                        <p>{item.title}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CounterBlog;
