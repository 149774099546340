import React, { useEffect, useState } from 'react'
import PageTitle from '../elements/PageTitle';
import "./Subscription.css"
import { useNavigate } from 'react-router-dom';
import freeWatch from '../assets/images/freeWatch.png'
const boxWrapper = [
    {
      image: "https://i.postimg.cc/QM8sxJwm/Frame-47696.png",
      title: "Elite",
      services: [
        "FREE MyCLNQ Pulse SmartWatch worth of INR 2999/-",
        "Two General Physician Online Consultation",
        "One time Dietitian consultation",
        "₹ 100 off on Specialist Consultation",
        "10% Off on Medical Products on MyCLNQ Marketplace",
        "20% off on Lab Tests",
        "Health Monitoring Dashboard",
        "Accumulate coins and buy health products via cash back coins"
      ],
      price:'2100',
      duration:"6 Months"
    },
    {
      image: "https://i.postimg.cc/GpXRBs1C/Frame-47694.png",
      title: "Connect",
      services: [
        "FREE MyCLNQ Pulse SmartWatch worth of INR 2999/-",
        "Four General Physician Online Consultation",
        "Health Assessment & Diet plan (two) with Goals",
        "Two times Dietitian consultation (every 6 months)",
        "₹ 100 off on Specialist Consultation",
        "15% Off on Medical Products on MyCLNQ Marketplace",
        "25% off on Lab Tests",
        "Health Monitoring Dashboard",
        "Accumulate coins and buy health products via cash back coins",
        "10% Off on International Doctor consultations (Singapore, Malaysia, UAE)"
      ],
      price:'3000',
      duration:"12 Months"
    },
    {
      image: "https://i.postimg.cc/50GMgd0K/Frame-47693.png",
      title: "Advantage",
      services: [
        "One General Physician Online Consultation",
        "One time Dietitian consultation",
        "₹ 100 off on Specialist Consultation",
        "10% Off on Medical Products on MyCLNQ Marketplace",
        "20% off on Lab Tests",
        "Health Monitoring Dashboard",
        "Accumulate coins and buy health products via cash back coins"
      ],
      price:'900',
      duration:"6 Months"
    },
    {
      image: "https://i.postimg.cc/tT7jFCXV/Frame-47692.png",
      title: "Plus",
      services: [
        "Two General Physician Online Consultation",
        "Health Assessment & Diet plan (two) with Goals",
        "Two times Dietitian consultation (every 6 months)",
        "₹ 100 off on Specialist Consultation",
        "10% Off on Medical Products on MyCLNQ Marketplace",
        "20% off on Lab Tests",
        "Health Monitoring Dashboard",
        "Accumulate coins and buy health products via cash back coins"
      ],
      price:'1500',
      duration:"12 Months"
    }
  ];
const Subscriptions = () => {
        const [hoverBox, setHoverBox] = useState(0);
        const [expanded, setExpanded] = useState(
          boxWrapper.reduce((acc, item) => ({ ...acc, [item.title]: false }), {})
        );
      const [data, setData]=useState([]);
        const navigate = useNavigate();
      
        const handleToggle = (title) => {
          setExpanded({ ...expanded, [title]: !expanded[title] });
        };
      
        const handleNavigation = (item) => {
          const matchedData = data.find(dataItem => dataItem.amount === item.amount);
          console.log(matchedData)
           if (matchedData) {
             navigate('/subscription', { state: { selectedPlan:item , matchedData:matchedData  } });
           } else {
             console.error('No matching data found');
           }
    
          // navigate('/subscription', { state: { selectedPlan: item } });
        };
        const [selectedAmount , setSelectedAmount]=useState([])
    
     
        const getData = async () => {
          try {
            // const response = await fetch('https://stagingapi.myclnqhealth.com/api/v1/membershipPlan/getAll');
            const response = await fetch('https://myclnqapi.ssivixlab.com/api/v1/membershipPlan/getAll');
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setData(data.data);
            const filteredPlans = data.data.filter(e => e.amount === '2100' || e.amount === '3000');
            setSelectedAmount(filteredPlans)
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        useEffect(() => {
          getData();
        }, []);
  return (
    <div  className="page-content bg-white">
         <PageTitle activePage="Membership" parentTitle="Home" />
         <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="section-head  hbm-title" style={{ marginTop:"2rem", marginBottom:"4rem"}}>
                  <h3 className=" " style={{fontSize:"28px"}}>
                  Explore our range of plans, each designed to offer unique benefits and access to exclusive health resources and events.
                  </h3>
                </div>
              </div>
         <div className="row" style={{paddingLeft:"40px", paddingRight:"40px"}}>
        {data.map((item, index) => (
          <div className="col-xl-3 col-md-6 m-b30" key={index}>
            <div
              className={`icon-bx-wraper style-1 box-hover ${index === hoverBox ? 'active' : ''}`}
              onMouseEnter={() => setHoverBox(index)}
            >
              <div className="icon-bx m-b30">
                <span className="icon-cell">
                  <img src={item.mainImage} alt="" />
                </span>
              </div>
              <div className="icon-content">
                <h5 className="dz-title m-b10">
                  <span onClick={() => handleNavigation(item)}>{item.planName}</span>
                </h5>
                <ol key={index} style={{ padding: "0rem" }}>
                {item.offerPercent === '0' ? item.description.split(',').slice(0, expanded[index] ? undefined : 7).map((desc, i) => (
                  <li key={i}>{`${i + 1}. ${desc.trim()}`}</li>
                )): 
  item.description.split(',').slice(0, expanded[index] ? undefined : 5).map((desc, i) => (
    <li key={i}>{`${i + 1}.  ${desc.trim()}`}</li>
  ))
  }

  {item.description.split(',').length > 5 && (
    <li
      key="toggle"
      onClick={() => handleToggle(index)}
      style={{ cursor: 'pointer', color: 'black' }}
    >
      {expanded[index] ? 'Show less' : 'Read more...'}
    </li>
  )}
</ol>     
                {/* <img src={offer} className='offer-img'/> */}
                  {item.offerPercent=== '0' ?null: <label className='off-reason'>{item.discountEvent}</label>}
               {item.offerPercent === '0' ? null : <div className='discount-percent'>{Math.floor(item.offerPercent)}% off</div>}
                <div className='price-section' style={item.offerPercent !== '0' ? {height:"6rem"}:null}>
                  <div className='price'>
                  <p className="service-item" style={{fontSize:'18px',  textDecoration: 'line-through', margin:"0rem"}}>{ item.offerPercent === '0' ?null:`Price: ₹ ${Math.floor(item.amount)} ` }</p>
                  <h4 className="service-item" style={{fontSize:'18px', textWrap:"nowrap"}}>Price: ₹ {Math.floor(item.finalPrice)}  </h4>
                    <h4 className="service-item" style={{fontSize:'14px',paddingBottom:'20px', fontWeight:'500'}}>{item.duration} Months</h4>
                  </div>
                  <div className='watch-free'>
                  {item.withDevice === true && <img src={freeWatch} style={item.offerPercent === '0' ?{height:"5rem",width:"6rem", position:"relative",left:"40%", bottom:"25%"}:{height:"5rem",width:"6rem", position:"relative",left:"40%"}}/>}
                  </div>
                </div>
               
              
                <button
                  onClick={() => handleNavigation(item)}
                  className="btn btn-primary shadow-primary btn-skew"
                >
                  <span>Buy {item.title}</span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Subscriptions
