import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import './VideoSlider.css'; // Import the custom CSS file
import VideoModalPlay from '../components/VideoModal';
import Myclnq from './myclnq.png'
import doctors from './doctors.png'
import comingsoon from './comingsoon.png'
import freeplan from './freeplan.png'
import hasmukh from './hasmukhLal.png'

const dataBlog = [
  // {
  //   video: "https://myclnq.co/wp-content/uploads/2024/08/teaser-1.mp4",
  //   thumbnail: comingsoon, // Thumbnail URL
  //   title: "Smart Watch",
  //   // price: 'INR-1299',
  //   changestyle: "box-3",
  //   className: "video-card" // ClassName for video cards
  // },
  // {
  //   video: "https://myclnq.co/wp-content/uploads/2024/08/teaser-2.mp4",
  //   thumbnail: freeplan, // Thumbnail URL
  //   title: "Smart Watch",
  //   // price: 'INR-1299',
  //   changestyle: "box-3",
  //   className: "video-card" // ClassName for video cards
  // },
  {
    video: "https://www.youtube.com/embed/yN5WlfJ1LR8?autoplay=1",
    thumbnail: hasmukh, // Thumbnail URL
    title: "Smart Watch",
    // price: 'INR-1299',
    changestyle: "box-3",
    className: "video-card" // ClassName for video cards
  },
  {
    video: "https://www.youtube.com/embed/aHIPTvkDO5A?autoplay=1",
    thumbnail: Myclnq, // Thumbnail URL
    title: "Smart Watch",
    // price: 'INR-1299',
    changestyle: "box-3",
    className: "video-card" // ClassName for video cards
  },
  {
    video: "https://myclnq.co/wp-content/uploads/2023/10/MyCLNQ-teleconsult-landscape.mp4",
    thumbnail: doctors, // Thumbnail URL
    title: "Smart Watch",
    // price: 'INR-1299',
    changestyle: "box-3",
    className: "video-card" // ClassName for video cards
  },
  

  // Add more video items as needed
];

function WorkoutSlider() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const paginationRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [isVideoOpen, setVideoOpen] = useState(false);

  const handleVideoOpen = (url) => {
    setVideoUrl(url);
    setVideoOpen(true);
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
  };

  return (
    <>
      <h2 className="title wow fadeInUp" style={{ textAlign: "center", fontSize: '40px', marginBottom: '5rem', paddingTop: "140px"}}>
         <span style={{color:'white'}}>Earn While</span><span style={{ marginBottom: '40px', color:"white" }} className="color-red-head"> <span style={{color:"#ff4848"}}>You Burn</span></span>
      </h2>
      <Swiper
        className="swiper portfolio-slider"
        slidesPerView={"auto"}
        spaceBetween={0}
        loop={true}
        speed={1500}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' + className + '"> 0' + (index + 1) + "</span>"
            );
          },
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.navigation.destroy();
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {dataBlog.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={`dz-box style-1 ${item.changestyle} ${item.className} bazzar-head`}>
              <div className="dz-media">
                <Link to={"/"}>
                  <div className="video-container">
                    <img
                      className="video-thumbnail"
                      src={item.thumbnail}
                      alt="Thumbnail"
                      onClick={() => handleVideoOpen(item.video)}
                    />
                    <div className="play-button" onClick={() => handleVideoOpen(item.video)}></div>
                    {/* <div className="video-overlay">
                      <h3 className="title">{item.title}</h3>
                      <h5 style={{ color: '#fff' }}>Price: {item.price}</h5>
                    </div> */}
                  </div>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="container">
          <div className="num-pagination">
            <div className="portfolio-button-prev btn-prev dark" ref={navigationPrevRef}>
              <i className="fa-solid fa-arrow-left"></i>
            </div>
            <div className="swiper-pagination dark style-1 m-lr-lg" ref={paginationRef}></div>
            <div className="portfolio-button-next btn-next dark" ref={navigationNextRef}>
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </Swiper>
      <VideoModalPlay
        isOpen={isVideoOpen}
        videoUrl={videoUrl}
        onClose={handleVideoClose}
      />
    </>
  );
}

export default WorkoutSlider;
