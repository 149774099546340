import React, { useEffect } from "react";
import Index from "./pages/Index";

//Css
import "./assets/vendor/switcher/switcher.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/css/style.css";
import { Provider } from "react-redux";
import store from "./store";

import ReactGA from "react-ga4";
const TRACKING_ID = "G-CMDTEVL9SF";

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
  }, []);

  return (
    <>
      <Provider store={store}>
        <Index />
      </Provider>
    </>
  );
}

export default App;
