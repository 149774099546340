import { useEffect, useRef, useState } from "react";
import {  Route, Routes, Outlet, HashRouter, useParams, useNavigate, BrowserRouter } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//layouts
import Header, { Mainheader } from "./../layouts/Header";
import Footer from "./../layouts/Footer";
import Footer2 from "../layouts/Footer2";
import ScrollToTop from "./../layouts/ScrollToTop";


//Pages
import Home from "./Home";
import AboutUs from "./AboutUs";
import Team from "./Team";
import Pricing from "./Pricing";
import Faq from "./Faq";
import Schedule from "./Schedule";
import ErrorPage from "./ErrorPage";
import UnderConstruction from "./UnderConstruction";
import ComingSoon from "./ComingSoon";
import Portfolio from "./Portfolio";
import PortfolioDetails from "./PortfolioDetails";
import Services from "./Services";
import ServicesDetails from "./ServicesDetails";
import Subscription from "../components/Subscription"
import BlogGrid from "./BlogGrid";
import BlogLargeSidebar from "./BlogLargeSidebar";
import BlogListSidebar from "./BlogListSidebar";
import BlogDetail from "./BlogDetail";
import Appointment from "./Appointment";
import WeightCalculator from "./WeightCalculator";
import ContactUs from "./ContactUs";
import Home2 from "./Home2";
import Header2 from "../layouts/Header2";
import Home3 from "./Home3";
import BlogDetailDengue from "./BlogDetailDengue";
import BlogDetailFitness from "./BlogDetailFitness";
import BlogDetailNutrition from "./BlogDetailNutrition";
import Success from "./Success";
import Failure from "./Failure";
import DietPlan from "./dietPlan";
import config from "../Utils/config";
import GetApp from "./getApp";
import Subscriptions from "./Subscriptions";
import LatestSlider from "./LatestSlider";
import PressDetails from "./PressDetails";
import DiwaliPage from "../components/DiwaliPage";

function Index() {
  var baseName = "/";
  const scrollTopBtn = useRef(null);
  const {id}=useParams()

  window.onscroll = () => {
    window.scrollY > 650
      ? scrollTopBtn.current.setAttribute("style", "display:block")
      : scrollTopBtn.current.setAttribute("style", "display:none");
  };
 
  return (
    <BrowserRouter basename={baseName}>
      <Routes>
        <Route
          path="/under-maintenance"
          exact
          element={<UnderConstruction />}
        />
            {/* <Route path="/diwali-offer" exact element={<DiwaliPage />} /> */}
        <Route path="/appointment" exact element={<Appointment />} />
        <Route path="/coming-soon" exact element={<ComingSoon />} />
        <Route path="/diet-plan/:id" exact element={<DietPlan />} />
        <Route element={<MainLayout />}>
          <Route path="/" exact element={<Home />} />
          <Route path="/about-us" exact element={<AboutUs />} />
          <Route path="/team" exact element={<Team />} />
          <Route path="/faq" exact element={<Faq />} />
          <Route path="/pricing" exact element={<Pricing />} />
          <Route path="/schedule" exact element={<Schedule />} />
          <Route path="/portfolio" exact element={<Portfolio />} />
          <Route
            path="/portfolio-details"
            exact
            element={<PortfolioDetails />}
          />
          <Route path="/services" exact element={<Services />} />
          <Route path="/subscription" exact element={<Subscription />} />
          <Route path="/subscriptions" exact element={<Subscriptions />} />
          <Route path="/payment-success" exact element={<Success />} />
          <Route path="/payment-failure" exact element={<Failure />} />
          <Route path="/blog-grid" exact element={<BlogGrid />} />
          <Route path="/get-app" exact element={<GetApp />} />
          <Route
            path="/blog-large-sidebar"
            exact
            element={<BlogLargeSidebar />}
          />
          <Route
            path="/blog-list-sidebar"
            exact
            element={<BlogListSidebar />}
          />
          <Route path="/:link" exact element={<BlogDetail />} />
          <Route path="/press/:link" exact element={<PressDetails />} />
          <Route path="/news-articles" exact element={<LatestSlider />} />
          {/* <Route path="/news-articles-dengue" exact element={<BlogDetailDengue /> } />
          <Route path="/news-articles-fitness" exact element={<BlogDetailFitness />} />
          <Route path="/news-articles-nutrition" exact element={<BlogDetailNutrition />} /> */}
          <Route
            path="/weight-calculator"
            exact
            element={<WeightCalculator />}
          />
          <Route path="/contact-us" exact element={<ContactUs />} />
        </Route>
        <Route element={<MainLayout2 />}>
          <Route path="/home-2" exact element={<Home2 />} />
        </Route>
        <Route element={<MainLayout3 />}>
          <Route path="/home-3" exact element={<Home3 />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      {/* <Switcher /> */}
      <ToastContainer />
      <ScrollToTop />
      <button
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        ref={scrollTopBtn}
        className="scroltop icon-up"
        type="button"
      >
        <i className="fas fa-arrow-up"></i>
      </button>
      </BrowserRouter>
  );
}

function MainLayout() {
  return (
    <div className="page-wraper">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}
function MainLayout2() {
  return (
    <div className="page-wraper">
      <Header2 />
      <Outlet />
      <Footer2 />
    </div>
  );
}
function MainLayout3() {
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);
  return (
    <div className="page-wraper">
      <header className="site-header mo-left header header-transparent style-1">
        <div
          className={`sticky-header mt-3 main-bar-wraper navbar-expand-lg ${
            headerFix ? "is-fixed" : ""
          }`}
        >
          <Mainheader />
        </div>
      </header>
      <Outlet />
    </div>
  );
}

export default Index;
