import { createSlice } from '@reduxjs/toolkit';
const initialState = { label: '' };
const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setTag: (state, action) => {
      state.label = action.payload; // Update the label key in the state
    },
  },
});

export const { setTag } = tagSlice.actions;
export default tagSlice.reducer;