import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { LuMail } from "react-icons/lu";
import email from '../assets/success/email.png';
import QR from '../assets/success/QR.png';
import GooglePlay from '../assets/success/GooglePlay.jpg';
import image from '../assets/success/image.png';
import check from '../assets/success/check.jpg';
import { useNavigate } from 'react-router-dom';
import './Success.css'

const Success = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone]=useState('');
  const navigate=useNavigate();
  useEffect(() => {
    // Retrieve the data from localStorage when the component mounts
    const storedName = localStorage.getItem("name");
    const storedEmail = localStorage.getItem("email");
    const storePhone = localStorage.getItem("phoneNumber")

    if (storedName) setName(storedName);
    if (storedEmail) setEmail(storedEmail);
    if (storePhone ) setPhone(storePhone);
  }, []);
  const handleBackBtn=()=>{
    navigate('/')
  }
   
  return (
    <div className='success-main'>

    <div className='header-success'>
    <div className='payment-success-text'>
    <img src={check} style={{height:"3rem",marginBottom:"1rem", background:"red"}}/>
       <h1>Payment Successful</h1>
       <p style={{fontSize:"1.2rem"}}>Thank you for your purchase. Your payment has been processed successfully.</p>
       <button className='btn-home' onClick={handleBackBtn}>Back to Home</button>
       <div className='letter'>
       <p className='title-qr'>Dear {name},</p>
       <p className='title-qr'>Thank you for joining the Healthy Bharat Mission, a movement dedicated to ensuring that every Indian achieves fitness and well-being. We are delighted to have you on board! As a member of the Healthy Bharat Mission, you can earn Cash Coins for every step or activity you complete. These coins can be redeemed in our marketplace for health and wellness products or easily used to renew your membership with us.</p>
       <p className='title-qr'>Our team of experienced health professionals is dedicated to providing you with accurate and up-to-date information, empowering you to make informed decisions about your health and well-being.</p>
       <p className='title-qr'>Please scan QR code or Type "MyCLNQ" in Google Play Store or Apple Store to download the app and start earning Cash Coins.</p>
       <p className='title-qr'>Kindly use this below email and phone details while sign Up.</p>
       <ul className='title-qr' ><li>Email: <label style={{color:"blue"}}>{email}</label></li> <li>phone: <label style={{color:"blue"}}>{phone}</label></li> </ul>
       <br/>
       <p className='title-qr'>Together, let’s transform our communities and contribute to making Bharat a healthier nation—Swasth Bharat.</p>
       <ul className='title-qr'>
        <li>Regards</li>
        <li>Cash Kar Har Kadam Team #CKHK</li>
        <li>MyCLNQ Health India</li>
       </ul>
        </div>
       </div>
       
    <div className='payment-success-image' style={{marginLeft:"3rem"}}>
      <img src={image}/>
      <p className='title-qr' style={{marginTop:"1rem"}}>Scan and Download MyCLNQ Mobile App</p>
      {/* <p className='title-qr'> Please scan below QR code or Type "MyCLNQ" in Google Play Store or Apple Store to download the app and start earning Cash Coins.</p> */}
       <div className='img-qr'>
       <img className='qr' src={QR} alt='QR'/>    
        <ul style={{  padding: 0, gap: "10px" }}>
                      <li>
                        <a href="https://apps.apple.com/us/app/myclnq/id1436460772" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://uploadimage.io/images/2024/08/03/appstore824ac46ada7a29ee.png"
                            alt="appstore"
                            className='apple-store'
                            style={{ border: 0 }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.ssivixlab.MYCLNQ&hl=en_US"
                          target="_blank"
                          rel="noopener noreferrer">
                          <img
                            src="https://uploadimage.io/images/2024/08/03/playstore5a73419e3782e3f8.png"
                            alt="playstore"
                            className="play-img"
                            style={{border: "0px",width: "190px",objectFit: "cover", height:"3rem"}}
                          />
                        </a>
                      </li>
                    </ul>
        </div>
      </div>
   </div>
        {/* <div className='header-success'>
  <img src={email} alt='email' className='mail-icon'/>
  <h1>Payment Successful</h1>
  <p>Thank you for your purchase. Your payment has been processed successfully.</p>
  <Link to="/" style={{color:"white"}}>Back to Home</Link>
  </div>
  <div className='img-google'>
  <p style={{color:"black", paddingTop:"1rem"}}>dowload our mobile app to get plans benefits</p>
<div className='qr-code-success'>
<img src={QR} alt='QR'/>    
<img src={GooglePlay} alt='QR'/>    
</div>
</div> */}
  </div>
  )
}

export default Success
