import React, { useState, useEffect } from 'react';
import mobileLeft from '../assets/images/about/mobileLeft.png'; // Change to a different image
// import mobileStraight from "../assets/images/about/straight-img.png"
import phoneQR from "../assets/images/about/phone-QR.png";
import './carousel.css'; // Import your custom CSS
import { IMAGES, SVGICON } from '../constants/theme';


const iconBox = [
  { image: "https://uploadimage.io/images/2024/08/03/walking-man_4112966.png", title: "Complete 7000 steps everyday " },
  { image: "https://uploadimage.io/images/2024/08/03/promotion_1436306.png", title: "Climb 5 floors everyday " },
  { image: "https://uploadimage.io/images/2024/08/03/sleeping_3242368.png", title: "Sleep for 6 hours a day" },
  { image: "https://uploadimage.io/images/2024/08/03/smartphone_5528829.png", title: "Keep your screen time below 4 hours" }
];

const iconDropBlog = [];

const sliderImages = [
  { src: mobileLeft, alt: "Mobile Left 1" },
//   { src: mobileLeft, alt: "Mobile Left 1" },
  {src:phoneQR,alt:"Phone QR"},
//   {src:mobileStraight,alt:"Mobile Straight",specialWidth: true }
];

const PerfectTrainers = () => {
  const [iconTitle, setIconTitle] = useState();
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePointerClick = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderImages.length);
    }, 3000); 

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="col-lg-6 m-b30 about-content custom-neg-marg">
        <div className="section-head">
          <span className="sub-title wow fadeInUp" data-wow-delay="0.2s">Healthy Bharat Mission</span>
          <h2 className="title wow fadeInUp" data-wow-delay="0.4s">Earn cash coins <br /><span>on every step you take</span></h2>
          <p className="wow fadeInUp" data-wow-delay="0.6s">Let's Earn ₹ Coins</p>
          <ul className="point-list">
            {iconDropBlog.map((item, ind) => (
              <li
                className={`icon-dropdown anm ${item.id === iconTitle ? "show" : ""}`}
                data-speed-x="-1"
                data-speed-scale="-1"
                onClick={() => {
                  setIconTitle(item.id);
                  if (item.id === iconTitle) {
                    setIconTitle("");
                  }
                }}
                key={ind}
              >
                <i className="fa-solid fa-plus"></i>
                <span>{item.title}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="row m-t40 m-sm-b20 m-b30">
          {iconBox.map((item, ind) => (
            <div className="col-sm-6 m-sm-b20 m-b30" key={ind}>
              <div className="icon-bx-wraper style-2">
                <div className="icon-bx">
                  <span className="icon-cell">
                    <img src={item.image} alt="" />
                  </span>
                </div>
                <div className="icon-content">
                  <h5 className="dz-title">{item.title}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-lg-6 m-b30">
        <div className="dz-media cashback-img phone-myclnq">
          <div className="custom-carousel">
                    {SVGICON.multilines}
            <div className="custom-carousel-inner" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
              {sliderImages.map((image, index) => (
                <div key={index} className={`custom-carousel-item ${index === currentSlide ? 'active' : ''}`}>
                <img 
                  src={image.src} 
                  alt={image.alt} 
                  className={`phone-myclnq-img ${image.specialWidth ? 'special-width' : ''}`} 
                />
                </div>
              ))}
           
              
            </div>
            <div className="custom-carousel-pointers" style={{marginTop:"20px"}}>
              {sliderImages.map((_, index) => (
                <button
                  key={index}
                  className={`custom-pointer ${index === currentSlide ? 'active' : ''}`}
                  onClick={() => handlePointerClick(index)}
                ></button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerfectTrainers;
