import { IMAGES } from "../constants/theme";

const dataPress = [
  // 2 Sep
  {
    image: IMAGES.press1,
    imageMain: IMAGES.press1,
    tag: "The Print",
    title:
      "MyCLNQ Health Launches “Healthy Bharat Mission”: Pioneering Initiative to Earn While Staying Healthy [Cash Kar Har Kadam]",
    date: "20 August, 2024 ",
    content:
      "New Delhi [India], August 20: MyCLNQ Health India is thrilled to announce the launch of the “Healthy Bharat Mission,”[...] ",
    // link: "press/ThePrint-press-release",
    link: "https://theprint.in/ani-press-releases/myclnq-health-launches-healthy-bharat-mission-pioneering-initiative-to-earn-while-staying-healthy-cash-kar-har-kadam/2230311/",
    additionalContent: {
      belowImgTitle:
        "MyCLNQ Health Launches “Healthy Bharat Mission”: Pioneering Initiative to Earn While Staying Healthy [Cash Kar Har Kadam]",
      date: "20 August, 2024",
      title: "VMPL",
      description: `New Delhi [India], August 20: MyCLNQ Health India is thrilled to announce the launch of the “Healthy Bharat Mission,” a groundbreaking initiative designed to inspire and empower Indians to lead healthier, more fulfilling lives. Launched on the occasion of India’s 78th Independence Day, this mission marks the first-of-its-kind effort in the country where individuals can earn cash coins simply by making healthy lifestyle choices. `,
      share: "⮂ Share ",
      description2:
        "In a nation where the demands of modern life often overshadow the importance of personal health, the “Healthy Bharat Mission” aims to revolutionize the way Indians approach their well-being. Imagine earning cash coins every time you make a healthy choice–whether it’s going for a walk, running, eating a nutritious meal, or getting a good night’s sleep. MyCLNQ innovative program allows participants to be rewarded for maintaining a healthy lifestyle, making wellness more accessible and motivating for everyone.",
      description3:
        "“We are incredibly proud to launch the ‘Healthy Bharat Mission’ and introduce a system where being healthy and fit is not only its own reward but also one that comes with tangible benefits,” said Anwar Rafique, Group CEO at MyCLNQ Health. “This initiative is designed to inspire every Indian to take control of their health in a way that’s fun, engaging, and financially rewarding.” MyCLNQ Health is empowering individuals to prioritize their well-being while being rewarded for their efforts.",
      description4:
        "“The ‘Healthy Bharat Mission’ is more than just a wellness program; it’s a movement aimed at creating a healthier nation. By combining financial incentives with healthy habits, this initiative seeks to create a lasting impact on the nation’s health. With the support of smart wearable technology and a commitment to holistic health”, said Bharat Maheshwari, Business Advisor at MyCLNQ Health India.",
      description5:
        "The ‘Healthy Bharat Mission’ utilizes advanced smart wearable technology to monitor and encourage healthy behaviours, offering participants the opportunity to earn rewards. These rewards can be redeemed for a variety of daily essentials, including Vitamins, Nutrition products and other miscellaneous items, making the journey to better health both practical and beneficial.",
      description7:
        "The initiative will encompass a multifaceted approach, including educational campaigns, community engagement programs, and partnerships with healthcare providers. By leveraging its extensive network and resources, Cash Kar Har Kadam aims to reach millions, inspiring them to adopt healthier lifestyles and preventive healthcare practices.",
      description8:
        "“Investing in preventive care is investing in our future. By promoting regular health check-ups, encouraging physical activity, and spreading awareness about nutrition, we aim to reduce the burden of preventable diseases,” added Gangesh Kumar Jha, Group COO at MyCLNQ Health.",
      description9:
        "As India celebrates its independence, MyCLNQ Health invites everyone to join the “Healthy Bharat Mission” and take the first step towards a healthier, more prosperous future.",
      description10:
        "“As we embark on this journey, our goal is to build a healthier nation by addressing the root causes of illness before they escalate. Preventive care is not just about avoiding disease; it’s about living life to the fullest. Through this initiative, we are committed to creating a healthier, happier Bharat,” added Pragnesh Patel, Chief Strategy Officer at MyCLNQ Health India.",
      description11:
        "Looking ahead, Cash Kar Har Kadam is set to expand its network of partners and introduce new features that will further enhance the user experience. The brand is also exploring opportunities to collaborate with corporates and financial institutions to offer even more ways for users to save and earn.",
      description12:
        "For more information on how to participate, please visit www.cashkarharkadam.com or download the MyCLNQ app.",
      description13: "About MyCLNQ Health India",
      description14:
        "MyCLNQ Health India is a leading digital health platform dedicated to improving the well-being of individuals through innovative technology and personalized care. With a focus on preventive health and wellness, The AI-powered MyCLNQ app offers a holistic 360-degree healthcare service designed to help people lead healthier, happier lives.",
      // description15: "Media Contact:",
      // description16: "Pragnesh Patel",
      // description17: "Chief Strategy Officer",
      // description18: "Chief Strategy Officer",
    },
  },
  //28August
  {
    image: "https://bsmedia.business-standard.com/_media/bs/img/article/2024-08/21/full/1724233124-4444.jpg",
    imageMain: "https://bsmedia.business-standard.com/_media/bs/img/article/2024-08/21/full/1724233124-4444.jpg",
    tag: "Business Standard",
    title: `MyCLNQ Health Launches "Healthy Bharat Mission": Pioneering Initiative to Earn While Staying Healthy [Cash Kar Har Kadam]`,
    date: "22 August, 2024",
    content:
      "New Delhi [India], August 20: MyCLNQ Health India is thrilled to announce the launch of the “Healthy Bharat Mission,”[...] ",
    // link: "press/business-standard-press-release",
    link: "https://www.business-standard.com/content/specials/myclnq-health-launches-healthy-bharat-mission-pioneering-initiative-to-earn-while-staying-healthy-cash-kar-har-kadam-124082100545_1.html",
    additionalContent: {
      belowImgTitle: `MyCLNQ Health Launches "Healthy Bharat Mission": Pioneering Initiative to Earn While Staying Healthy [Cash Kar Har Kadam]`,
      date: "Friday, September 13, 2024",
      description: `India, August 15, 2024 – MyCLNQ Health India is thrilled to announce the launch of the "Healthy Bharat Mission," a groundbreaking initiative designed to inspire and empower Indians to lead healthier, more fulfilling lives. Launched on the occasion of India’s 78th Independence Day, this mission marks the first-of-its-kind effort in the country where individuals can earn cash coins simply by making healthy lifestyle choices.`,
      share: "⮂ Share ",
      description2: `In a nation where the demands of modern life often overshadow the importance of personal health, the "Healthy Bharat Mission" aims to revolutionize the way Indians approach their well-being. Imagine earning cash coins every time you make a healthy choice—whether it's going for a walk, running, eating a nutritious meal, or getting a good night’s sleep. MyCLNQ innovative program allows participants to be rewarded for maintaining a healthy lifestyle, making wellness more accessible and motivating for everyone.
`,
      description3: `“We are incredibly proud to launch the 'Healthy Bharat Mission' and introduce a system where being healthy and fit is not only its own reward but also one that comes with tangible benefits,” said Anwar Rafique, Group CEO at MyCLNQ Health. “This initiative is designed to inspire every Indian to take control of their health in a way that’s fun, engaging, and financially rewarding.”MyCLNQ Health is empowering individuals to prioritize their well-being while being rewarded for their efforts.
`,
      description4: `“The ‘Healthy Bharat Mission’ is more than just a wellness program; it’s a movement aimed at creating a healthier nation. By combining financial incentives with healthy habits, this initiative seeks to create a lasting impact on the nation’s health.With the support of smart wearable technology and a commitment to holistic health”, said Bharat Maheshwari, Business Advisor at MyCLNQ Health India.
`,
      description5: `The 'Healthy Bharat Mission' utilizes advanced smart wearable technology to monitor and encourage healthy behaviours, offering participants the opportunity to earn rewards. These rewards can be redeemed for a variety of daily essentials, including Vitamins, Nutrition products and other miscellaneous items, making the journey to better health both practical and beneficial.
`,
      description7: `The initiative will encompass a multifaceted approach, including educational campaigns, community engagement programs, and partnerships with healthcare providers. By leveraging its extensive network and resources, Cash Kar Har Kadam aims to reach millions, inspiring them to adopt healthier lifestyles and preventive healthcare practices. 
`,
      description8: `"Investing in preventive care is investing in our future. By promoting regular health check-ups, encouraging physical activity, and spreading awareness about nutrition, we aim to reduce the burden of preventable diseases," added Gangesh Kumar Jha, Group COO at MyCLNQ Health.
`,
      description9: `As India celebrates its independence, MyCLNQ Health invites everyone to join the "Healthy Bharat Mission" and take the first step towards a healthier, more prosperous future.
`,
      description10: `"As we embark on this journey, our goal is to build a healthier nation by addressing the root causes of illness before they escalate. Preventive care is not just about avoiding disease; it's about living life to the fullest. Through this initiative, we are committed to creating a healthier, happier Bharat," added Pragnesh Patel, Chief Strategy Officer at MyCLNQ Health India.
`,
      description11: `Looking ahead, Cash Kar Har Kadam is set to expand its network of partners and introduce new features that will further enhance the user experience. The brand is also exploring opportunities to collaborate with corporates and financial institutions to offer even more ways for users to save and earn.
`,
      description12: `For more information on how to participate, please visit www.cashkarharkadam.com or download the MyCLNQ app.
`,
      description13: "About MyCLNQ Health India",
      description14: `MyCLNQ Health India is a leading digital health platform dedicated to improving the well-being of individuals through innovative technology and personalized care. With a focus on preventive health and wellness, The AI- powered MyCLNQ appoffers a holistic 360-degree healthcare services designed to help people lead healthier, happier lives.
`,
      // "MyCLNQ Health India is a leading digital health platform dedicated to improving the well-being of individuals through innovative technology and personalized care. With a focus on preventive health and wellness, The AI-powered MyCLNQ app offers a holistic 360-degree healthcare service designed to help people lead healthier, happier lives.",
    },
  },
  //27August
  {
    image: "https://bsmedia.business-standard.com/_media/bs/img/article/2024-07/02/full/20240702175357.jpg",
    imageMain: "https://bsmedia.business-standard.com/_media/bs/img/article/2024-07/02/full/20240702175357.jpg",
    tag: "Business Standard",
    title:
      "Nervfit and MyCLNQ Announce Collaboration to Expand Technology Driven Preventive Healthcare in India",
    date: "2 July, 2024 ",
    content:
      "Bangalore (Karnataka) [India], July 2: Nervfit, a prominent player in wearable technology, and MyCLNQ Health Singapore,  [...]",
    // link: "press/Cambodians-press",
    link: "https://www.business-standard.com/content/press-releases-ani/nervfit-and-myclnq-announce-collaboration-to-expand-technology-driven-preventive-healthcare-in-india-124070201009_1.html",
    additionalContent: {
      date: "27 August 2024",
      belowImgTitle:
        "Visa study shows card use growing among Cambodians, fueling financial inclusion",
      description:
        "A Balanced Approach to Wellness In the quest for a healthier lifestyle, the 80/20 diet and fitness rule has emerged as a popular and practical approach. This concept, embraced by nutritionists and fitness enthusiasts, offers a flexible way to maintain balance while achieving wellness goals. Here’s a comprehensive look at what the 80/20 rule entails and how it can be effectively applied to both diet and fitness.",
      share: "⮂ Share ",
    },
  },
];

export default dataPress;
