import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NewsLetter from "../elements/NewsLetter";
import PageTitle from "../elements/PageTitle";
import Select from "react-select";
import { Link } from "react-router-dom";
import { sha512 } from "js-sha512";
import freeWatch from "../assets/images/freeWatch.png";
import { toast } from "react-toastify";

const plans = [
  {
    image: "https://i.postimg.cc/QM8sxJwm/Frame-47696.png",
    title: "Elite",
    services: [
      "FREE MyCLNQ Pulse SmartWatch worth of INR 2999/-",
      "Two General Physician Online Consultation",
      "One time Dietitian consultation",
      "₹ 100 off on specialist consultation",
      "10% Off on Medical Products on MyCLNQ Marketplace",
      "20% off on Lab Tests",
      "Health Monitoring Dashboard",
      "Accumulate coins and buy health products via cash back coins",
    ],
    price: "2100",
    duration: "6 Months",
  },
  {
    image: "https://i.postimg.cc/GpXRBs1C/Frame-47694.png",
    title: "Connect",
    services: [
      "FREE MyCLNQ Pulse SmartWatch worth of INR 2999/-",
      "Four General Physician Online Consultation",
      "Health Assessment & Diet plan (two) with Goals",
      "Two times Dietitian consultation (every 6 months)",
      "₹ 100 off on specialist consultation",
      "15% Off on Medical Products on MyCLNQ Marketplace",
      "25% off on Lab Tests",
      "Health Monitoring Dashboard",
      "Accumulate coins and buy health products via cash back coins",
      "10% Off on International Doctor consultations (Singapore, Malaysia, UAE)",
    ],
    price: "3000",
    duration: "12 Months",
  },
  {
    image: "https://i.postimg.cc/50GMgd0K/Frame-47693.png",
    title: "Advantage",
    services: [
      "One General Physician Online Consultation",
      "One time Dietitian consultation",
      "₹ 100 off on specialist consultation",
      "10% Off on Medical Products on MyCLNQ Marketplace",
      "20% off on Lab Tests",
      "Health Monitoring Dashboard",
      "Accumulate coins and buy health products via cash back coins",
    ],
    price: "900",
    duration: "6 Months",
  },
  {
    image: "https://i.postimg.cc/tT7jFCXV/Frame-47692.png",
    title: "Plus",
    services: [
      "Two General Physician Online Consultation",
      "Health Assessment & Diet plan (two) with Goals",
      "Two times Dietitian consultation (every 6 months)",
      "₹ 100 off on specialist consultation",
      "10% Off on Medical Products on MyCLNQ Marketplace",
      "20% off on Lab Tests",
      "Health Monitoring Dashboard",
      "Accumulate coins and buy health products via cash back coins",
    ],
    price: "1500",
    duration: "12 Months",
  },
];
const stateOptions = [
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  { value: "Assam", label: "Assam" },
  { value: "Bihar", label: "Bihar" },
  { value: "Chhattisgarh", label: "Chhattisgarh" },
  { value: "Goa", label: "Goa" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Haryana", label: "Haryana" },
  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Manipur", label: "Manipur" },
  { value: "Meghalaya", label: "Meghalaya" },
  { value: "Mizoram", label: "Mizoram" },
  { value: "Nagaland", label: "Nagaland" },
  { value: "Odisha", label: "Odisha" },
  { value: "Punjab", label: "Punjab" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Sikkim", label: "Sikkim" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Telangana", label: "Telangana" },
  { value: "Tripura", label: "Tripura" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Uttarakhand", label: "Uttarakhand" },
  { value: "West Bengal", label: "West Bengal" },
  { value: "Andaman and Nicobar Islands", label: "Andaman and Nicobar Islands" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Dadra and Nagar Haveli", label: "Dadra and Nagar Haveli" },
  { value: "Daman and Diu", label: "Daman and Diu" },
  { value: "Delhi", label: "Delhi" },
  { value: "Lakshadweep", label: "Lakshadweep" },
  { value: "Puducherry", label: "Puducherry" },
];

const Subscription = () => {
  const [data, setData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstnameError]=useState('');
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameerror]=useState('');
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [id, setId] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [payuData, setPayuData] = useState([]);
  const location = useLocation();
  const [focusedIndex, setFocusedIndex] = useState(0);
  const form = useRef();
  // const initialPlan = location.state?.selectedPlan || plans[0];
  const initialPlan = location.state?.selectedPlan || null;
  const matchedData = location.state?.matchedData || {};
  const [selectedPlan, setSelectedPlan] = useState(initialPlan);
  const [state, setstate] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(stateOptions);
  const [showDropdown, setShowDropdown] = useState(false);
  const [emailError, setEmailError] = useState('');
  const scroll = stateOptions.length > 10;
  const environmentPayU =  "PRODUCTION";
  // console.log(state);

  const getData = async () => {
    try {
      // const response = await fetch("https://stagingapi.myclnqhealth.com/api/v1/membershipPlan/getAll");
      const response = await fetch("https://myclnqapi.ssivixlab.com/api/v1/membershipPlan/getAll");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setData(data.data);
      {!initialPlan && setSelectedPlan(data.data[0])}
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getData();

  }, []);
 

  useEffect(() => {
    console.log("Initial Plan:", initialPlan);
    console.log("Matched Data:", matchedData._id);
    {selectedPlan !== null && setId(initialPlan._id);}
  }, [initialPlan]);

  useEffect(() => {
    if (showDropdown && filteredOptions.length > 0) {
      const handleKeyDown = (event) => {
        if (event.key === "Tab") {
          event.preventDefault();
          handleOptionClick(filteredOptions[focusedIndex]);
        }
      };
  
      document.addEventListener("keydown", handleKeyDown);
  
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [showDropdown, filteredOptions, focusedIndex]);
  
 
  useEffect(() => {
    console.log(focusedIndex, "foxud");
    const handleKeyDown = (e) => {
      if (showDropdown) {
        switch (e.key) {
          case "ArrowDown":
            setFocusedIndex((prev) => Math.min(prev + 1, filteredOptions.length - 1));
            break;
          case "ArrowUp":
            setFocusedIndex((prev) => Math.max(prev - 1, 0));
            break;
          case "Enter":
            if (focusedIndex >= 0 && focusedIndex < filteredOptions.length) {
              handleOptionClick(filteredOptions[focusedIndex]);
            }
            break;
          case "Escape":
            setShowDropdown(false);
            break;
          default:
            break;
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [showDropdown, focusedIndex, filteredOptions]);
  

  const handleInputChange = (e) => {
    let { value } = e.target;
    value = value.replace(/[^a-zA-Z\s]/g, '');
    value = value.replace(/^\s+/, '');
  
    setstate(value);
    const filtered = stateOptions.filter((option) =>
      option.value.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setShowDropdown(true);
  };
  
  const handleOptionClick = (option) => {
    setstate(option.value);
    setShowDropdown(false);
  };

  const handlePlanChange = (event) => {
    const selectedData = data.find((plan) => plan.planName === event.target.value);
    setSelectedPlan(selectedData);
  }; 

  useEffect(() => {
    {selectedPlan !== null && setId(selectedPlan._id)}
  }, [selectedPlan]);
// console.log(firstNameError)
  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateState()) {
      return;
    }
   
    const emailInput = e.target.querySelector('input[name="dzEmail"]');
    const email = emailInput.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    } else {
      setEmailError("");
    }
    if(emailError){
      setEmailError("Please Enter a Valid Email.")
    }

    if (pincode.length < 6) {
      setPincodeError("Please Enter a Valid Pincode");
    }

    if(phoneNumber.length < 10){
      setPhoneError('Please Enter a Valid Phone Number')
    }
    if(firstName.length < 2 ){
      setFirstnameError('Invalid First Name');
    }else if(firstNameError.length >= 2){
      setFirstnameError('')
    }
    if(lastName.length <2){
      setLastNameerror('Invalid Last Name')
    }else if(firstNameError.length >= 2){
      setLastNameerror('')
    }

    console.log(firstName.length,lastName,email,phoneNumber,addressLine1,addressLine2,pincode);
    const data = {
      name: `${firstName} ${lastName}`,
      email: email,
      phoneNumber: phoneNumber,
      phoneNumber: phoneNumber,
      state: state,
      city: city,
      pincode: pincode,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
    };
    console.log(data);
    localStorage.setItem("name", `${firstName} ${lastName}`);
    localStorage.setItem("email", email);
    localStorage.setItem("phoneNumber", phoneNumber);
   if(!firstNameError && !lastNameError && !emailError && !phoneError && addressLine1 && addressLine2 && !pincodeError){
    postData();
   }else{
    toast.error("Please Fill All Fields.");
   }
    };

  const validateState = () => {
    if (!state) {
      return false;
    }
    return true;
  };

  const postData = async () => {
    console.log(id);
    // const url = `https://stagingapi.myclnqhealth.com/api/v1/membershipPlan/purchase/web/${id}`;
    const url = `https://myclnqapi.ssivixlab.com/api/v1/membershipPlan/purchase/web/${id}`;
    const body = JSON.stringify({
      name: `${firstName} ${lastName}`,
      email: email,
      phoneNumber: phoneNumber,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      state: state,
      city: city,
      pincode: pincode,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setPayuData(data);
    } catch (error) {
      if (error.message.includes("status: 400")) {
        window.alert("You already have an active plan. Please signup to avail benefits.");
      } else {
        window.alert("something went wrong please try after some time");
      }
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    startPayment();
  }, [payuData]);

  const handleSubmitForm = (redirectUrl, postData) => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = redirectUrl;

    // Append form fields to the form
    for (const [key, value] of Object.entries(postData)) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = value;
      form.appendChild(input);
    }
    // Append the form to the document body
    document.body.appendChild(form);
    // Submit the form
    form.submit();
    // Optionally, you can return the form element or its reference to further operations
    return form;
  };

  const startPayment = async () => {
    setPayuData("");
    if (payuData.status === "success") {
      let payU = await payuData.data.paymentData;
      let payUparams = {
        key: environmentPayU === "STAGE" ? "J8Lw10" : process.env.REACT_APP_MERCHANT_KEY,
        transactionId: payU.txnId,
        amount: payU.amount,
        name: payU.firstName,
        email: payU.email,
        phone: payU.phone,
        environment: environmentPayU === "STAGE" ? "1" : "0",
        surl: environmentPayU === "STAGE" ? "https://myclnq.co/stage/india-new/#/payment-success" : "https://cashkarharkadam.com/payment-success",
        furl: environmentPayU === "STAGE" ? "https://myclnq.co/stage/india-new/#/payment-failure" : "https://cashkarharkadam.com/payment-failure",
        SALT: environmentPayU === "STAGE" ? "7vMEFaXy0V9iR1ZP1YupzscIz1LYRz0S" : process.env.REACT_APP_MERCHANT_SALT,
        productInfo: payU.productName,
      };
      console.log("payU", JSON.stringify(payUparams));
      const { key, transactionId, amount, productInfo, firstName, email, phone, environment, surl, furl, SALT } = payUparams;
      const url = environmentPayU === "STAGE" ? "http://localhost:3000/stage/india-new/" : "https://myclnq.co/web/india-new/";

      console.log(typeof key, typeof transactionId, typeof amount, typeof productInfo, typeof firstName, typeof email, typeof SALT);
      const dataString = `${key}|${transactionId}|${amount}|${productInfo}|${firstName}|${email}|||||||||||${SALT}`;
      const hash = sha512(dataString);
      console.log(hash);
      const postData = {
        key,
        txnid: transactionId,
        amount,
        firstname: firstName,
        email,
        phone,
        productinfo: productInfo,
        surl,
        furl,
        hash,
      };

      // Test Environment	https://test.payu.in/_payment
      // Production Environment	https://secure.payu.in/_payment

      const redirectUrl = environmentPayU === "STAGE" ? "https://test.payu.in/_payment" : "https://secure.payu.in/_payment";
      // Call the function to handle form submission
      handleSubmitForm(redirectUrl, postData);
      // console.log("submt clicked");
    }
  };

  const validatePhone = (value) => {
    const trimmedValue = value.trim();
    if (trimmedValue.length === 0 ) {
      setPhoneError('')
      return !value.startsWith(" ");
    }else if(trimmedValue.length < 10){
      setPhoneError('invalid phone number')
    }
    else if(trimmedValue.length === 10){
      setPhoneError('')
    }
    const isValid = /^\d{1,10}$/.test(trimmedValue);
    return isValid;
  };



  const validateAddress = (address) => {
    const trimmedAddress = address.trim();

    // Check if the address is empty after trimming
    if (trimmedAddress.length === 0) {
      // Reject input with leading spaces if the address is empty
      return !address.startsWith(" ");
    }

    const minLength = 0;
    const isValid = trimmedAddress.length >= minLength;

    return isValid;
  };
  function validateCity(value) {
    const trimmedValue = value.trim();
    if (trimmedValue.length === 0) {
      return !value.startsWith(" ");
    }
    const isValid = /^[a-zA-Z][a-zA-Z\s]*$/.test(trimmedValue);
    return isValid;
  }
  const validateEmail = (email) => {
   const emailRegex = /^[^\s@]+@[^\s@]+\.(com|net|org|edu)$/i;

   if (email === '') {
    setEmailError('');
  } else if (!emailRegex.test(email)) {
    setEmailError('Please enter a valid email.');
  } else {
    setEmailError('');
  }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value.trimStart();
    validateEmail(value);
      setEmail(value);
  };

  return (
    <div className="page-content bg-white">
      <PageTitle activePage="Membership" parentTitle="Home" />
      <section className="content-inner-2 z-index-none">
        <div className="container">
          <div className="row align-items-center">
            <h3 style={{ textAlign: "center", marginBottom: "30px", color: "#333333" }}>Pick the Right Membership Plan for Your Fitness Goals</h3>
            <div className="col-md-6 col-xl-5 m-sm-b30 m-xl-b0">
              <div className="contact-box subscription-detail subscription">
                <h3 className="contact-title">{selectedPlan !== null && selectedPlan.planName}</h3>
                <img src={selectedPlan !== null &&  selectedPlan.mainImage} alt={selectedPlan !== null &&  selectedPlan.planName} className="img-fluid mb-3" />
                <ul>
                  {selectedPlan !== null &&  selectedPlan.description.split(",").map((service, index) => (
                    <li key={index} style={{ color: "#ddd" }}>
                      {index + 1}. {service.trim()}
                    </li>
                  ))}
                </ul>

                {selectedPlan !== null && selectedPlan.offerPercent === "0" ? null : (
                  <div className="discount-percent" style={{ margin: "1rem 0rem 0rem -0.5rem", width: "35%" }}>
                    {selectedPlan !== null &&  Math.floor(selectedPlan.offerPercent)}% off
                  </div>
                )}
                <div className="price-section">
                  <div style={selectedPlan !== null && selectedPlan.offerPercent === "0" ? { marginTop: "1rem" } : null}>
                    {selectedPlan !== null &&  selectedPlan.offerPercent === "0" ? null : (
                      <p className="service-item" style={{ fontSize: "18px", textDecoration: "line-through", marginTop: "0.5rem", color: "#ffffff" }}>
                        Price: ₹ {selectedPlan !== null &&  Math.floor(selectedPlan.amount)}{" "}
                      </p>
                    )}
                    <h4 className="mt-3" style={{ color: "#fff" }}>
                      Price: ₹ {selectedPlan !== null &&  Math.floor(selectedPlan.finalPrice)}
                    </h4>
                    <h4 className="service-item" style={{ fontSize: "14px", paddingBottom: "20px", fontWeight: "500", color: "white" }}>
                      {selectedPlan !== null && selectedPlan.duration} Months
                    </h4>
                  </div>
                  <div>{selectedPlan !== null && selectedPlan.withDevice === true && <img src={freeWatch} className="free-watch-img2" />}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-7 form-div">
              <form className="dz-form dzForm style-1" ref={form} onSubmit={sendEmail}>
                <input type="hidden" className="form-control" name="dzToDo" value="Contact" />
                <div className="dzFormMsg"></div>
                <div className="row">
                  <div className="col-xl-12 h-80">
                    <div className="input-group input-line m-b30">
                      <select
                        name="dzPlan"
                        className="form-control"
                        style={{ color: "#9B9B9B" ,   borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140"><polyline points="10,50 70,110 130,50" fill="none" stroke="%23000" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat right center`,
                          backgroundColor: "white",
                          backgroundSize: "12px", 
                          backgroundPosition: "right 12px center",}}
                        
                        onChange={handlePlanChange}
                        value={selectedPlan !== null && selectedPlan.planName}>
                        {data.map((plan, index) => (
                          <option key={index} value={plan.planName}>
                            &nbsp;{plan.planName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 h-80">
                    <div className="input-group input-line">
                      <input
                        style={{ color: "#9B9B9B" }}
                        name="dzFirstName"
                        required
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^a-zA-Z\s]/g, ''); 
                          if(value.length === 0){
                            setFirstnameError('')
                            setFirstName(value.trimStart());}
                          else if(value.length < 2){
                            setFirstnameError('Invalid First Name')
                            setFirstName(value.trimStart());
                          }else if(value.length >= 2){
                            setFirstnameError('')
                            setFirstName(value.trimStart());
                          }
                        }}
                      />
                  </div>
                  {firstNameError && <p className="text-danger error-msg-subscription">{firstNameError}</p>}
                  </div>
                  <div className="col-lg-6 h-80">
                    <div className="input-group input-line">
                      <input
                        style={{ color: "#9B9B9B" }}
                        name="dzLastName"
                        required
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^a-zA-Z\s]/g, ''); 
                          if(value.length === 0){
                            setLastNameerror('');
                            setLastName(value.trimStart());
                          }else if(value.length < 2){
                            setLastNameerror('Invalid Last Name');
                            setLastName(value.trimStart());
                          }else if(value.length >= 2){
                            setLastNameerror('')
                            setLastName(value.trimStart());
                          }
                        }}
                      />
                  </div>
                  {lastNameError && <p  className="text-danger error-msg-subscription">{lastNameError}</p>}
                  </div>
                  <div className="col-xl-6 h-80">
                    <div className="input-group input-line">
                      <input
                        style={{ color: "#9B9B9B" }}
                        name="dzEmail"
                        required
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </div>
                    {emailError && <p  className="text-danger error-msg-subscription">{emailError}</p>}
                  </div>
                  <div className="col-xl-6 h-80">
                    <div className="input-group input-line">
                      <input
                        style={{ color: "#9B9B9B" }}
                        name="dzPhoneNumber"
                        required
                        type="tel"
                        className="form-control"
                        placeholder="Phone"
                        value={phoneNumber}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (validatePhone(value)) {
                            if(value.length === 0){
                              setPhoneError('');
                              setPhoneNumber(value);
                            }
                            else if(value.length < 10){
                              setPhoneError('Please Enter a Valid Phone Number')
                              setPhoneNumber(value);
                            }else if(value.length === 10){
                              setPhoneError('')
                              setPhoneNumber(value);
                            }else{
                              setPhoneError('')
                            }
                          }
                        }}
                      />
                    </div>
                    {phoneError && <p  className="text-danger error-msg-subscription">{phoneError}</p>}
                  </div>
                  <div className="col-xl-12 h-80">
                    <div className="input-group input-line">
                      <input
                        name="dzPhoneNumber"
                        required
                        type="text"
                        style={{ color: "#9B9B9B" }}
                        className="form-control"
                        placeholder="Address Line 1"
                        value={addressLine1}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (validateAddress(value)) {
                            setAddressLine1(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 h-80">
                    <div className="input-group input-line m-b30">
                      <textarea
                        style={{ height: "3.2rem", color: "#9B9B9B" }}
                        name="dzMessage"
                        rows="2"
                        required
                        className="form-control"
                        placeholder="Address line 2"
                        value={addressLine2}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (validateAddress(value)) {
                            setAddressLine2(value);
                          }
                        }}></textarea>
                    </div>
                  </div>
                  <div className="col-xl-6 h-80">
                    <div className="input-group input-line">
                      <input
                        style={{ color: "#9B9B9B" }}
                        name="dzPhoneNumber"
                        required
                        type="text"
                        className="form-control"
                        placeholder="City"
                        value={city}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (validateCity(value)) {
                            setCity(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 h-80">
                    <div className="input-group input-line">
                      {/* <select name="dzPlan" className="form-control" style={{color:"#9B9B9B"}} onChange={handleStateChange} value={state} defaultValue={'State'}>
                        {stateOptions.map((plan, index) => (
                          <option key={index} value={plan.value}>
                            {plan.value}
                          </option>
                        ))}
                      </select> */}
                      <input
                        type="text"
                        name="dzPlan"
                        className="form-control"
                        style={{ color: "#9B9B9B" }}
                        value={state}
                        onChange={handleInputChange}
                        onFocus={() => setShowDropdown(true)}
                        onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
                        placeholder="State"
                        autoComplete="off"
                        required
                      />
                      {showDropdown && (
                        <div className="dropdown-menu show mt-5" style={scroll ? { overflowY: "scroll", maxHeight: "200px" } : {}} >
                          {filteredOptions.length === 0 ? (
                            <div className="dropdown-item">No state found</div>
                          ) : (
                            filteredOptions.map((option, index) => (
                              <div
                                key={index}
                                className="dropdown-item"
                                style={{ backgroundColor: focusedIndex === index ? "#f1f1f1" : "white" }}
                                onClick={() => handleOptionClick(option)}>
                                {option.value}
                              </div>
                            ))
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 h-80">
                    <div className="input-group input-line">
                      <input
                        style={{ color: "#9B9B9B" }}
                        name="dzPhoneNumber"
                        required
                        type="text"
                        className="form-control"
                        placeholder="Pincode"
                        value={pincode}
                        onChange={(e) => {
                          // Filter out non-numeric characters
                          const newValue = e.target.value.replace(/[^0-9]/g, "");
                          // Ensure the length is at most 6 digits
                          if(newValue.length === 0){
                            setPincodeError('');
                            setPincode(newValue);
                          }
                          else if (newValue.length < 6){
                            setPincodeError('Please Enter Valid Pincode');
                            setPincode(newValue);
                          }
                          else if (newValue.length === 6) {
                            setPincodeError('')
                            setPincode(newValue);
                          }
                        }}
                      />
           
                    </div>
                    {pincodeError && <p className="text-danger error-msg-pincode">{pincodeError}</p>}
                  </div>

                  <div className="col-sm-12">
                    <button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg btn-skew">
                      <span>Proceed to Buy</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div className="container content-inner-1">
        <div className="map-iframe">
          {/* <iframe
              title="myFrame"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28896.531392443423!2d75.81462525569334!3d25.133445080066668!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x537f208422eb8b26!2sSkyview%20smart%20solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1646364642086!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe> */}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
