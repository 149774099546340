import React, { useState } from "react";
import appStore from "../assets/images/appstoresLogo.png";
import playStore from "../assets/images/playStoresLogo.png";
import huawei from "../assets/images/huwawei.png";
import phone from "../assets/images/about/mobile-image.png";
import qr from "../assets/images/about/myclnqQr.webp";
import "./getApp.css";

const GetApp = () => {
  return (
    <>
      <div className="getapp-main">
        <div className="second-section-img">
          <div className="first-img ">
            <label>Scan to Download</label>
            <span className="added-shadow">
              <img src={qr} className="qr-image" />
            </span>

            <div className="app-imgs">
              <a href="https://apps.apple.com/us/app/myclnq/id1436460772" target="_blank">
                <img
                  src={appStore}
                  className="app-store-img"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.ssivixlab.MYCLNQ&hl=en_US" target="_blank">
                <img src={playStore} className="play-store-img"/>
              </a>
            </div>
          </div>

          <div className="second-img">
            <img src={phone} className="mobile-image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default GetApp;
